export default function BackspaceSvg() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0935 27.4453L39.3121 36.6639"
        stroke="white"
        strokeWidth="1.43269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0935 36.6639L39.3121 27.4453"
        stroke="white"
        strokeWidth="1.43269"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0409 19.7277H45.0402H45.0344H45.0341L24.9204 19.738L24.9201 19.738C24.0751 19.7388 23.2399 19.9187 22.4695 20.2659C21.6991 20.613 21.0111 21.1195 20.4507 21.752L20.4506 21.7521L14.8285 28.1012L14.8281 28.1016C13.8618 29.1948 13.3284 30.6036 13.3284 32.0626C13.3284 33.5217 13.8618 34.9305 14.8281 36.0237L14.8284 36.0241L20.4458 42.3679L20.4459 42.368C21.0067 43.001 21.6953 43.5078 22.4664 43.855C23.2375 44.2021 24.0735 44.3818 24.9192 44.382H24.9197L45.044 44.3717L45.0449 44.3717C46.6282 44.3687 48.1457 43.7381 49.2649 42.6181C50.3842 41.4982 51.0137 39.9802 51.0157 38.3969V38.3961L51.0157 25.7033L51.0157 25.7026C51.0139 24.1185 50.3839 22.5998 49.2637 21.4797C48.1436 20.3596 46.6249 19.7295 45.0409 19.7277ZM17.818 19.4212L18.265 19.817C19.0989 18.8751 20.1232 18.1209 21.2702 17.6041C22.4171 17.0873 23.6607 16.8197 24.9187 16.819C24.9187 16.819 24.9187 16.819 24.9187 16.819L45.0344 16.8086L45.0347 16.8086L45.0403 16.8086C47.3993 16.8086 49.6618 17.7457 51.3298 19.4138C52.9979 21.0819 53.935 23.3443 53.935 25.7033V38.3962C53.935 40.7542 52.9986 43.0158 51.3317 44.6837C49.6648 46.3516 47.4038 47.2894 45.0458 47.2908L24.9253 47.3012H24.925H24.9195C23.6606 47.3012 22.416 47.0339 21.2681 46.5171C20.1201 46.0003 19.095 45.2457 18.2604 44.3031L17.8135 44.6989L18.2604 44.3031L12.643 37.9593C11.2027 36.3328 10.4075 34.2353 10.4075 32.0627C10.4075 29.8901 11.2027 27.7926 12.643 26.166L12.1961 25.7703L12.643 26.166L18.265 19.817L17.818 19.4212ZM45.0437 43.7747L24.9193 43.7851L45.0402 20.3247C46.4662 20.3263 47.8333 20.8935 48.8416 21.9018C49.85 22.9101 50.4171 24.2773 50.4188 25.7033V38.3961C50.417 39.8215 49.8502 41.188 48.8427 42.1961C47.8352 43.2043 46.4691 43.772 45.0437 43.7747Z"
        fill="white"
        stroke="#8E96BC"
        strokeWidth="1.19391"
      />
    </svg>
  );
}
