/* eslint-disable no-restricted-globals */
import { Button, Form, Input, notification } from 'antd';
import React from 'react';
import useLocalStorage from '../configs/localStorage';
import { useAxiosPost } from '../configs/axios';
import '../assets/styles/pages/login.scss';
import logo from '../assets/images/logo.png';
import logIn from '../assets/images/login.gif';
import { localStorageName } from '../configs/constants';
import { Navigate } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const { request: loginRequest, loading: loginLoading } =
    useAxiosPost('/auth/Login');

  const onLogin = async (values) => {
    try {
      const loginData = await loginRequest({
        username: values.username,
        password: values.password
      });
      setAuth({
        token: loginData.data.token,
        user: loginData.data.user,
        permissions: loginData.data.permissions
      });

      location.replace(loginData?.data?.permissions[0].permission_name);

      // const findRoute = loginData?.data?.permissions?.find(per => per?.permission_name?.includes('/'));

      // if (findRoute) {
      //   location.replace(findRoute.permission_name);
      // } else {
      //   location.replace('/');
      // }
    } catch (error) {
      notification.error({
        message: "Can't login",
        description: error.response.data.message,
        placement: 'bottomRight',
        duration: 2
      });

      console.log('🚀 ~ file: Login.jsx:27 ~ onLogin ~ error', error);
    }
  };
  if (auth) {
    return <Navigate to="/" />;
  }
  return (
    <main className="main-login">
      <img alt="lezzoo" src={logo} className="lezzoo-logo" />
      <div className="form-container">
        <div className="form">
          <h1 className="login-header">
            {t('login')}
          </h1>
          <p className="login-description">
            {t('enter_your_cred')}
          </p>
          <Form
            layout="vertical"
            className="form-layout"
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t('please_username')
                }
              ]}
            >
              <Input size="large" placeholder={t('username')} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('please_pass')
                }
              ]}
              name="password"
            >
              <Input.Password
                size="large"
                placeholder={t('password')}
                type="password"
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              className="login-button"
              // loading={loginLoading}
              htmlType="submit"
            >
              {t('login')}
            </Button>
          </Form>
          <div className="login-error">
            {/* {loginError && loginError.response.data.msg} */}
          </div>
        </div>
        <div>
          <img src={logIn} alt="" />
        </div>
      </div>
    </main>
  );
};

export default Login;
