import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ModalNote({ open, onDrawerClose, dispatch, note }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (note) {
      form.setFieldValue('note', note);
    }
  }, []);

  return (
    <Modal
      open={open}
      onOk={() => onDrawerClose()}
      onCancel={onDrawerClose}
      footer={
        <Row>
          <Button
            onClick={onDrawerClose}
            style={{ flex: 1, height: 50, fontSize: 20 }}
          >
            {t('close')}
          </Button>
          <Button
            onClick={() => {
              const note = form.getFieldValue('note');
              dispatch({ type: 'SET_NOTE', note: note });
              onDrawerClose();
            }}
            style={{
              flex: 1,
              backgroundColor: '#6DFF96',
              height: 50,
              border: 'none',
              fontSize: 20
            }}
          >
            {t('save_note')}
          </Button>
        </Row>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          dispatch('SET_NOTE', {
            note: values.note
          });
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: '100%'
          }}
        >
          <Form.Item name="note" label={t('add_special_note')}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <button
            type="button"
            onClick={() => {
              form.resetFields();
            }}
            style={{ color: '#fff', textDecoration: 'underline' }}
          >
            {t('clear')}
          </button>
        </Space>
      </Form>
    </Modal>
  );
}
