import {
  Button,
  Col,
  Form,
  InputNumber,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import RemoteSelect from '../../../utils/RemoteSelect';
import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { Fragment, useState } from 'react';

export default function CheckoutPart({ states, dispatch }) {
  const { t } = useTranslation();
  const [formDiscount] = Form.useForm();
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <div className={style['checkout-partner']}>
      <Row gutter={[20, 20]}>
        <Col span={10}>
          <Space
            direction="vertical"
            style={{
              width: '100%'
            }}
          >
            <p>{t('customer')}</p>
            <RemoteSelect
              className={style['select']}
              labelForm={t('select_customer')}
              nameForm="customer_id"
              placeholder="Select customer"
              style={{ minWidth: '100%', fontSize: '0.8rem' }}
              allowClear
              filterFromServer={true}
              endpoint="/customers/list"
              valueOption="customer_id"
              defaultValue={states.customerId}
              optionText={(obj) => {
                return (
                  <Fragment>
                    {obj?.customer_name}{' '}
                    {obj?.discount_value ? (
                      obj?.discount_type == 'percentage' ? (
                        <Tag color="red">
                          <Fragment>%{obj.discount_value}</Fragment>
                        </Tag>
                      ) : (
                        <Tag color="green">{obj.discount_value}</Tag>
                      )
                    ) : null}
                  </Fragment>
                );
              }}
              onValueChange={({ objectLabel }) => {
                dispatch({
                  type: 'SET_STATE',
                  changeStates: {
                    customerInfo: objectLabel
                  }
                });
              }}
            />
          </Space>
        </Col>
        <Col span={10}>
          <Space
            direction="vertical"
            style={{
              width: '100%'
            }}
          >
            <p className="wh-clr">{t('discount')}</p>
            <Popover
              open={openPopover}
              className={style['checkout-popover']}
              placement="top"
              title={t('discount')}
              trigger="click"
              onOpenChange={(set) => setOpenPopover(set)}
              content={
                <Form
                  form={formDiscount}
                  layout="vertical"
                  onFinish={(values) => {
                    setOpenPopover(false);
                    if (values.discount_type) {
                      dispatch({
                        type: 'SET_STATE',
                        changeStates: {
                          discountType: values.discount_type,
                          discountValue: values.discount_value
                        }
                      });
                    } else {
                      notification.warning({
                        message: 'Please Select Discount Type'
                      });
                    }
                  }}
                >
                  <Row
                    style={{
                      width: '200px'
                    }}
                  >
                    <Col span={24}>
                      <Form.Item name="discount_type">
                        <Select
                          placeholder="Discount type"
                          options={[
                            {
                              label: t('percentage_discount'),
                              value: 'percentage'
                            },
                            {
                              label: t('fixed_discount'),
                              value: 'fixed'
                            }
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="discount_value"
                        rules={[
                          {
                            message: t('required'),
                            required: true
                          },
                          {
                            validator: (_, value) => {
                              return new Promise((resolve, reject) => {
                                const type =
                                  formDiscount.getFieldValue('discount_type');
                                if (!type) {
                                  notification.warning({
                                    message: t('please_select_discount_type')
                                  });
                                  reject(new Error());
                                } else if (type === 'fixed') {
                                  resolve();
                                } else if (
                                  type === 'percentage' &&
                                  value <= 100
                                ) {
                                  resolve();
                                } else {
                                  notification.warning({
                                    message: `${t('maximum')} ${
                                      type === 'fixed'
                                        ? total.toLocaleString()
                                        : '100%'
                                    }`
                                  });
                                  reject();
                                }
                              });
                            }
                          }
                        ]}
                      >
                        <InputNumber min={0} placeholder={t('amount')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          style={{
                            background: 'var(--light-green-color)',
                            borderWidth: 0,
                            width: '100%'
                          }}
                        >
                          {t('save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              }
            >
              <Button
                style={{
                  width: '100%'
                }}
              >
                {t('discount')}
              </Button>
            </Popover>
          </Space>
        </Col>
        <Col span={4}>
          <Space
            direction="vertical"
            style={{
              width: '100%'
            }}
          >
            <p>{t('auto_apply')}</p>
            <Switch
              onChange={(e) => {
                dispatch({
                  type: 'SET_STATE',
                  changeStates: {
                    isSetCustomerDiscount: e
                  }
                });
              }}
            />
          </Space>
        </Col>

        <Col span={10}>
          <Space
            direction="vertical"
            style={{
              width: '100%'
            }}
          >
            <p>{t('paymentType')}</p>
            <Select
              defaultValue={states.paymentType}
              className={style['select']}
              allowClear={true}
              size="large"
              onChange={(e) => {
                dispatch({
                  type: 'SET_STATE',
                  changeStates: {
                    paymentType: e
                  }
                });
              }}
              style={{ width: '100%' }}
            >
              <Select.Option value="cash">{t('cash')}</Select.Option>
              <Select.Option value="online">{t('online')}</Select.Option>
            </Select>
          </Space>
        </Col>

        <Col span={10}>
          <Space
            direction="vertical"
            style={{
              width: '100%'
            }}
          >
            <p>{t('partner')}</p>
            <RemoteSelect
              style={{
                width: '100%'
              }}
              className={style['select']}
              endpoint="/partners/list"
              allowClear
              filterFromServer
              onValueChange={({ objectLabel }) => {
                dispatch({
                  type: 'SET_STATE',
                  changeStates: {
                    partnerId: objectLabel ? objectLabel.partner_id : undefined
                  }
                });
              }}
              valueOption="partner_id"
              labelOption="name"
              defaultValue={states.partnerId}
              query={{
                offset: 0,
                limit: 10
              }}
            />
          </Space>
        </Col>
      </Row>
    </div>
  );
}
