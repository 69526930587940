export default function GiftSvg({ color = '#c1d2ec' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 512 512"
      id="gift-box"
      fill={color}
    >
      <path d="M274.7 443.5h93.8c31 0 56.3-25.2 56.3-56.3V256h-150v187.5zM406 143.5h-23.3c2.6-5.8 4.5-12.2 4.5-18.8 0-31.5-33-56.3-75-56.3-23.8 0-43.1 18.4-56.3 39.8-13.3-21.4-32.6-39.8-56.3-39.8-42.2 0-75 24.8-75 56.3 0 6.6 1.7 12.9 4.3 18.8H106c-20.8 0-37.5 16.9-37.5 37.5v37.5h168.8v-56.3h37.5v56.3h168.8V181c-.1-20.6-17-37.5-37.6-37.5zm-206.2 0c-22.9 0-37.5-11.1-37.5-18.8s14.6-18.8 37.5-18.8c11.3 0 25.1 20.3 32.4 37.5h-32.4zm112.5 0h-32.6c7.3-17.3 21.2-37.5 32.6-37.5 22.9 0 37.5 11.1 37.5 18.8s-14.7 18.7-37.5 18.7zM87.2 387.2c0 31 25.2 56.3 56.3 56.3h93.8V256h-150v131.2z"></path>
    </svg>
  );
}
