export default function NoteSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M16.6905 10.5653V18.277C16.6905 18.4747 16.612 18.6644 16.4721 18.8042C16.3323 18.9441 16.1426 19.0226 15.9449 19.0226H4.75994C4.56217 19.0226 4.37251 18.9441 4.23267 18.8042C4.09283 18.6644 4.01427 18.4747 4.01427 18.277V9.32903H9.48967L13.9644 4.85505H4.75994C3.52661 4.85505 2.52295 5.85872 2.52295 7.09204V18.277C2.52295 19.5103 3.52661 20.514 4.75994 20.514H15.9449C17.1782 20.514 18.1819 19.5103 18.1819 18.277V9.07327L16.6905 10.5653ZM18.935 6.21067L19.9893 5.1563C20.1318 5.01873 20.2454 4.85417 20.3235 4.67222C20.4017 4.49028 20.4428 4.29459 20.4446 4.09657C20.4463 3.89855 20.4086 3.70217 20.3336 3.5189C20.2586 3.33562 20.1479 3.16911 20.0078 3.02908C19.8678 2.88906 19.7013 2.77832 19.518 2.70334C19.3347 2.62835 19.1384 2.59062 18.9403 2.59234C18.7423 2.59406 18.5466 2.6352 18.3647 2.71336C18.1827 2.79152 18.0182 2.90513 17.8806 3.04757L16.8262 4.10194L18.935 6.21067Z"
        fill="#E4343E"
      />
      <path
        d="M15.7719 5.15601L10.5001 10.4286L9.97217 13.0652L12.6088 12.5373L17.8814 7.26548L15.7719 5.15601Z"
        fill="#E4343E"
      />
    </svg>
  );
}
