export const handlePrintClick = (content) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  iframe.contentDocument.write(content);
  setTimeout(() => {
    iframe.contentDocument.close();
    iframe.contentWindow.print();
    document.body.removeChild(iframe);
  }, 500);
};
