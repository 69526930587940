import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: []
};

export const cartSocketSlice = createSlice({
  name: 'cartSocket',
  initialState,
  reducers: {
    addCartFromSocket: (state, action) => {
      state.cart.push(action.payload);
    },
    emptyCartSocket: (state, action) => {
      state.cart = [];
    }
  }
});
