import { CloseCircleOutlined } from '@ant-design/icons';
import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { useTranslation } from 'react-i18next';
import { Row, Spin } from 'antd';
import { discountCalculate } from '../../../function/discountCalculate';
import { localStorageName } from '../../../configs/constants';
import useLocalStorage from '../../../configs/localStorage';
import SingleSelectedCard from './SingleSelectedCard';
import { useAxiosPost } from '../../../configs/axios';
import { handlePrintClick } from '../../../function/printOnClick';
import { useState } from 'react';
import HowMuchPaidDrawer from './HowMuchPaidDrawer';
import ModalNote from './ModalNote';

export default function CartStore({ states, dispatch }) {
  const { t } = useTranslation();
  const [showHowMuchPaidDrawer, setShowHowMuchPaidDrawer] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);

  const [auth] = useLocalStorage(localStorageName);
  const vendorInfo = auth?.user;

  let subtotal = 0;
  let totalPrice = 0;
  let discount = 0;

  const itemsArray = Object.values(states?.items || {});
  const sorted = itemsArray.sort((a, b) => {
    return b.sort - a.sort;
  });

  //Calculate Total Price
  sorted.forEach((item) => {
    subtotal += item.totalSingleProductPrice;

    // check discount per product;
    if (item.discount_type && item.discount_value && item.discount_amount) {
      discount += item.discount_amount;
    }
    // we saved totalPrice
  });

  totalPrice = subtotal - discount;

  // Calculate Discount for order
  if (states.discountType && states.discountValue) {
    discount += discountCalculate(
      totalPrice,
      states.discountType,
      states.discountValue
    );
    totalPrice = subtotal - discount;
  }

  // Calculate Discount for Customer
  if (
    states?.customerInfo?.discount_type &&
    states?.customerInfo?.discount_value &&
    states?.isSetCustomerDiscount
  ) {
    discount += discountCalculate(
      totalPrice,
      states.customerInfo.discount_type,
      states.customerInfo.discount_value
    );
    totalPrice = subtotal - discount;
  }

  // update the value of total price if have any discount or not.
  totalPrice = subtotal - discount;

  function resetStates() {
    dispatch({
      type: 'SET_STATE',
      changeStates: {
        discountType: '',
        discountValue: 0,
        customerInfo: {},
        isSetCustomerDiscount: false,
        partnerId: undefined,
        paymentType: 'cash',
        items: {}
      }
    });
  }

  const { request: placeOrderRequest, loading: orderPlacementLoading } =
    useAxiosPost('/orders');

  function placeOrder() {
    const body = {
      order_type: 'direct_sell',
      discount_type: states.discountType,
      discount_value: states.discountValue,
      order_payment_type: states.paymentType,
      customer_id: states?.customerInfo?.customer_id,
      customer_discount_value: states.isSetCustomerDiscount
        ? states.customerInfo.discount_value
        : 0,
      customer_discount_type: states.isSetCustomerDiscount
        ? states.customerInfo.discount_type
        : null,
      order_partner: states.partnerId,
      order_total: totalPrice,
      carts: [],
      order_instruction: states?.note
    };

    const checkItems = { ...states.items } || {};

    Object.values(checkItems).map((item) => {
      item.quantity = item.quantity - (item?.numberGift || 0); // because we don't calculate the gift
      item.gift_quantity = item?.numberGift;
      body.carts.push(item);
    });

    placeOrderRequest(body).then((res) => {
      const qr = res?.qrCode;

      import('../../../components.js/print/templateCheckout').then(
        async (module) => {
          const getHtml = module.templateCheckout({
            data: body,
            vendor: vendorInfo,
            subtotal: subtotal,
            customer: states?.customerInfo?.customer_name,
            discount_amount: discount,
            qrCode: qr,
            orderId: res.orderId,
            currentUser: auth?.user?.user_name
          });
          handlePrintClick(getHtml);
          resetStates();
        }
      );
    });
  }

  return (
    <section className={style['cart-store']}>
      <div className={style['cart-store-header']}>
        <p>{t('order')}</p>
        <button
          onClick={() => {
            resetStates();
          }}
        >
          <CloseCircleOutlined
            style={{ color: 'var(--red-color)', fontWeight: 'bold' }}
          />{' '}
          {t('clear')}
        </button>
      </div>
      <div className={style['cart-store-body']}>
        {sorted?.map((item) => {
          return (
            <SingleSelectedCard
              style={style}
              key={item.product_id}
              product={item}
              dispatch={dispatch}
            />
          );
        })}
      </div>

      <div className={style['cart-store-footer']}>
        <div className={style['note']}>
          <div>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1875 0.59375H1.81251C1.43955 0.59375 1.08187 0.741908 0.818143 1.00563C0.55442 1.26935 0.406262 1.62704 0.406262 2V13.25C0.405139 13.5178 0.481203 13.7803 0.625353 14.006C0.769503 14.2317 0.975632 14.4111 1.21907 14.5227C1.40595 14.6101 1.60969 14.6557 1.81603 14.6562C2.14623 14.6554 2.46551 14.5378 2.71743 14.3244L2.72587 14.3166L4.93931 12.4062H14.1875C14.5605 12.4062 14.9182 12.2581 15.1819 11.9944C15.4456 11.7306 15.5938 11.373 15.5938 11V2C15.5938 1.62704 15.4456 1.26935 15.1819 1.00563C14.9182 0.741908 14.5605 0.59375 14.1875 0.59375ZM13.9063 10.7188H4.62501C4.4225 10.7184 4.22671 10.7913 4.07376 10.9241L2.09376 12.6334V2.28125H13.9063V10.7188Z"
                fill="white"
              />
            </svg>
            <p>{states?.note ? states.note : t('any_special_instruction')}</p>
          </div>
          <button onClick={() => setShowNoteModal(true)}>
            {t(states?.note ? 'edit_note' : 'add_note')}
          </button>
        </div>
        <Row>
          <p>{t('subtotal')}</p>
          <span>
            {subtotal?.toLocaleString()}
            {vendorInfo?.default_currency_code}
          </span>
        </Row>
        <Row>
          <p>{t('discount')}</p>
          <span>
            {discount?.toLocaleString()}
            {vendorInfo?.default_currency_code}
          </span>
        </Row>
        <hr />
        <Row className={style['cart-total']}>
          <p>{t('total')}</p>
          <p>
            {totalPrice?.toLocaleString()}
            {vendorInfo?.default_currency_code}
          </p>
        </Row>

        <button
          disabled={orderPlacementLoading}
          className={style['btn-place-order']}
          onClick={() => {
            let permission = false;
            for (let i = 0; i < (auth?.permissions || []).length; i++) {
              const per = auth?.permissions[i];
              if (per.permission_name == 'calculator_in_order') {
                permission = true;
              }
            }
            if (permission) {
              setShowHowMuchPaidDrawer(true);
            } else {
              placeOrder();
            }
          }}
        >
          {orderPlacementLoading ? <Spin /> : t('place_order')}
        </button>
      </div>

      <HowMuchPaidDrawer
        open={showHowMuchPaidDrawer}
        setOpen={setShowHowMuchPaidDrawer}
        placeOrder={placeOrder}
        totalPrice={totalPrice}
        orderPlacementLoading={orderPlacementLoading}
      />
      <ModalNote
        dispatch={dispatch}
        open={showNoteModal}
        onDrawerClose={() => {
          setShowNoteModal(false);
        }}
        note={states?.note}
      />
    </section>
  );
}
