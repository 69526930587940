export default function CloseShiftSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_3955_5848)">
        <path
          d="M12.0001 22.0581C10.5812 22.0589 9.18408 21.7096 7.9325 21.0412C6.68092 20.3729 5.61356 19.4061 4.82502 18.2265C4.03647 17.047 3.55111 15.6911 3.41195 14.2791C3.27279 12.867 3.48412 11.4425 4.02722 10.1317C4.57032 8.82089 5.42841 7.66431 6.52544 6.76448C7.62246 5.86465 8.92454 5.24935 10.3162 4.97313C11.708 4.69691 13.1463 4.76829 14.5038 5.18095C15.8614 5.59361 17.0961 6.3348 18.0987 7.33882C18.2296 7.47438 18.302 7.65594 18.3004 7.84439C18.2988 8.03285 18.2232 8.21312 18.0899 8.34638C17.9567 8.47964 17.7764 8.55523 17.5879 8.55687C17.3995 8.55851 17.2179 8.48606 17.0824 8.35514C16.0772 7.34993 14.7965 6.66538 13.4022 6.38803C12.008 6.11069 10.5628 6.25302 9.24947 6.79702C7.93612 7.34102 6.81358 8.26226 6.0238 9.44424C5.23402 10.6262 4.81248 12.0159 4.81248 13.4374C4.81248 14.859 5.23402 16.2486 6.0238 17.4306C6.81358 18.6126 7.93612 19.5338 9.24947 20.0778C10.5628 20.6218 12.008 20.7641 13.4022 20.4868C14.7965 20.2095 16.0772 19.5249 17.0824 18.5197C17.2179 18.3888 17.3995 18.3163 17.5879 18.318C17.7764 18.3196 17.9567 18.3952 18.0899 18.5285C18.2232 18.6617 18.2988 18.842 18.3004 19.0304C18.302 19.2189 18.2296 19.4005 18.0987 19.536C17.2984 20.3375 16.3477 20.9729 15.3011 21.4057C14.2545 21.8385 13.1326 22.0602 12.0001 22.0581Z"
          fill="#1E2633"
        />
        <path
          d="M15.5938 17.75C15.4031 17.75 15.2204 17.6742 15.0856 17.5394L11.4918 13.9457C11.357 13.8109 11.2813 13.6281 11.2812 13.4375V8.40625C11.2812 8.21563 11.357 8.03281 11.4918 7.89802C11.6266 7.76323 11.8094 7.6875 12 7.6875C12.1906 7.6875 12.3734 7.76323 12.5082 7.89802C12.643 8.03281 12.7188 8.21563 12.7188 8.40625V13.1399L16.1019 16.5231C16.2024 16.6236 16.2708 16.7517 16.2985 16.8911C16.3263 17.0305 16.312 17.175 16.2576 17.3063C16.2033 17.4376 16.1112 17.5498 15.993 17.6288C15.8748 17.7078 15.7359 17.75 15.5938 17.75Z"
          fill="#1E2633"
        />
        <path
          d="M21.3438 14.1562H16.3125C16.1219 14.1562 15.9391 14.0805 15.8043 13.9457C15.6695 13.8109 15.5938 13.6281 15.5938 13.4375C15.5938 13.2469 15.6695 13.0641 15.8043 12.9293C15.9391 12.7945 16.1219 12.7188 16.3125 12.7188H21.3438C21.5344 12.7188 21.7172 12.7945 21.852 12.9293C21.9868 13.0641 22.0625 13.2469 22.0625 13.4375C22.0625 13.6281 21.9868 13.8109 21.852 13.9457C21.7172 14.0805 21.5344 14.1562 21.3438 14.1562Z"
          fill="#FF5348"
        />
        <path
          d="M19.1876 16.3125C19.0455 16.3124 18.9065 16.2703 18.7884 16.1913C18.6702 16.1123 18.5781 16.0001 18.5237 15.8687C18.4693 15.7374 18.4551 15.5929 18.4828 15.4535C18.5105 15.3141 18.5789 15.1861 18.6794 15.0855L20.329 13.4375L18.6845 11.7894C18.5535 11.6538 18.4811 11.4722 18.4827 11.2838C18.4844 11.0953 18.56 10.9151 18.6932 10.7818C18.8265 10.6485 19.0068 10.573 19.1952 10.5713C19.3837 10.5697 19.5652 10.6421 19.7008 10.7731L21.857 12.9293C21.9918 13.0641 22.0675 13.2469 22.0675 13.4375C22.0675 13.628 21.9918 13.8108 21.857 13.9456L19.7008 16.1019C19.6335 16.1692 19.5534 16.2225 19.4653 16.2587C19.3772 16.2948 19.2828 16.3131 19.1876 16.3125Z"
          fill="#FF5348"
        />
        <path
          d="M13.4375 3.375H10.5625C10.3719 3.375 10.1891 3.29927 10.0543 3.16448C9.91948 3.02969 9.84375 2.84687 9.84375 2.65625C9.84375 2.46563 9.91948 2.28281 10.0543 2.14802C10.1891 2.01323 10.3719 1.9375 10.5625 1.9375H13.4375C13.6281 1.9375 13.8109 2.01323 13.9457 2.14802C14.0805 2.28281 14.1562 2.46563 14.1562 2.65625C14.1562 2.84687 14.0805 3.02969 13.9457 3.16448C13.8109 3.29927 13.6281 3.375 13.4375 3.375Z"
          fill="#1E2633"
        />
      </g>
      <defs>
        <clipPath id="clip0_3955_5848">
          <rect
            width="23"
            height="23"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
