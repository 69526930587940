import { Tabs, Button, Row, Col, Space, Flex, Empty, notification } from 'antd';
import { useDispatch } from 'react-redux';
import LezzooOrderCard from './LezzooOrderCard';
import AllDayPanelDrawer from '../KitchenView/AllDayPanelDrawer';
import { useEffect, useState, useRef } from 'react';
import styles from './LezzooOrders.module.css';
import arrowLeft from '../../assets/svg/arrowLeft.svg';
import filterIcon from '../../assets/svg/filterIcon.svg';
import DrawerDirection from '../../utils/DrawerDirection';
import { useAxiosGet } from '../../configs/axios';
import { useTranslation } from 'react-i18next';
import cartChangeAudio from '../../audio/cart_change.mp3';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useSocketContext } from '../../socket/SocketContext';
import { OrderStatusesEnum } from '../../configs/constants';
import { incrementLezzooOrdersCount } from '../../store/ordersSlice';

const LezzooOrder = ({ onClose }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const audioRef = useRef(new Audio(cartChangeAudio));

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const {
    data: dataOrders,
    request: ordersRequest,
    setData: setLezzooOrders
  } = useAxiosGet('/orders/lezzooOrders', {
    autoRun: true,
    offset: 0,
    limit: 100
  });

  const { data: cancelationReasons } = useAxiosGet('/cancelationReasons/list', {
    autoRun: true,
    offset: 0,
    limit: 100
  });

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const detectNewOrders = (orders) => {
    const ordersWithoutPrepTime = orders?.filter(
      (order) =>
        !order.order_preparing_est_at && order.order_status != 'completed'
    );

    if (ordersWithoutPrepTime.length > 0) {
      playSound();
    }
  };

  useEffect(() => {
    if (dataOrders) {
      detectNewOrders(dataOrders?.orders);
    }
  }, [dataOrders]);

  const socket = useSocketContext();

  useEffect(() => {
    if (socket) {
      socket.on('orderCreation', (data) => {
        setLezzooOrders((old) => {
          return { orders: [...old.orders, data.order] };
        });
        // dispatch(
        //   addToOrdersList({
        //     ...data.order
        //     // order_kitchen_received_at: dayjs(
        //     //   data.order?.order_kitchen_received_at
        //     // ).format('HH:mm')
        //   })
        // );
        dispatch(incrementLezzooOrdersCount());
        notification.success({
          message: t('newOrder'),
          description: t('newOrderDesc'),
          placement: 'top',
          duration: 10
        });

        audioRef?.current?.play();
      });
      socket.on('orderStatusChanged', (data) => {
        const orderIndex = dataOrders?.orders.findIndex(
          (order) => order.order_id === data.orderId
        );
        if (orderIndex !== -1) {
          dataOrders.orders[orderIndex].order_status = data.order.order_status;
          if (data.order?.order_status === OrderStatusesEnum.InTheKitchen) {
            dataOrders.orders[orderIndex].order_preparing_est_at =
              data.order.order_kitchen_received_at;
          }
        }
      });
      return () => {
        socket.off('orderCreation');
        socket.off('orderStatusChanged');
      };
    }
  }, [socket, dataOrders]);

  const renderOrderCards = (filteredOrders) => {
    if (filteredOrders?.length === 0) {
      return (
        <Empty
          description={
            <p style={{ color: '#fff' }}>{t('noOrdersAvailable')}</p>
          }
        />
      );
    }
    return (
      <Row gutter={[16, 16]}>
        {filteredOrders?.map((order) => (
          <Col
            key={order.order_id}
            xs={24}
            sm={12}
            md={12}
            lg={10}
            xl={8}
            xxl={5}
          >
            <LezzooOrderCard
              cancelationReasons={cancelationReasons}
              order={order}
              refresh={ordersRequest}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const lezzooCount = dataOrders?.orders?.filter((order) => {
    return order.order_type === 'delivery' && order.order_status !== 'prepared';
  }).length;

  // const takeAwayCount = orders?.filter(
  //   (order) => order.order_type === 'takeaway' && !order.completed
  // ).length;
  // const dinInCount = orders?.filter(
  //   (order) => order.order_type === 'dinein' && !order.completed
  // ).length;

  const handleTabChange = (key) => {
    ordersRequest({ order_status: key });
  };

  return (
    <DrawerDirection
      open
      width="80%"
      closeIcon={null}
      onClose={onClose}
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
    >
      <section>
        <Row style={{ marginBottom: 32 }} onClick={onClose}>
          <Row style={{ gap: 12, cursor: 'pointer', color: '#fff' }}>
            <div style={{ marginTop: '5px' }}>
              <FaArrowLeftLong size={20} />
            </div>
            <h2>{t('backHome')}</h2>
          </Row>
        </Row>
        <Row className={styles.container}>
          <h1 className={styles['title']}>{t('LezzooOrders')}</h1>
          <Flex vertical gap={16}>
            <Button type="primary" onClick={toggleDrawer}>
              <img src={filterIcon} />
              {t('Categorize')}
            </Button>
            <div className={styles.buttonContainer}>
              <div className={`${styles.indicator} ${styles.lezzoo}`} />
              <span className={styles.count}>
                🔴 {lezzooCount} Lezzoo orders{' '}
              </span>
              {/* <div className={`${styles.indicator} ${styles.takeAway}`} />
              <span className={styles.count}>
                {' '}
                {takeAwayCount} {t('take_away')}{' '}
              </span>{' '}
              <div className={`${styles.indicator} ${styles.dinIn}`} />
              <span className={styles.count}>
                {dinInCount} {t('dine_in')}{' '}
              </span>{' '} */}
            </div>
          </Flex>
        </Row>
        <Tabs
          defaultActiveKey="1"
          centered
          tabBarStyle={{
            color: 'black'
          }}
          tabBarGutter={16}
          onChange={handleTabChange}
        >
          <Tabs.TabPane
            tab={
              <span className={styles['tabsTitle']}>{t('active_orders')}</span>
            }
            key="Active Orders"
          >
            {renderOrderCards(
              dataOrders?.orders?.filter(
                (order) => order.order_status !== 'prepared'
              )
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className={styles['tabsTitle']}>
                {t('Completed Orders')}
              </span>
            }
            key="completed"
          >
            {renderOrderCards(
              dataOrders?.orders?.filter((order) =>
                ['completed', 'prepared'].includes(order.order_status)
              )
            )}
          </Tabs.TabPane>
        </Tabs>

        <DrawerDirection
          closeIcon={null}
          onClose={toggleDrawer}
          open={drawerVisible}
          width="45%"
          title={
            <Space
              onClick={toggleDrawer}
              style={{ color: 'var(--third-color)', cursor: 'pointer' }}
            >
              <img src={arrowLeft} />
              {t('Categorize')}
            </Space>
          }
        >
          <AllDayPanelDrawer />
        </DrawerDirection>
      </section>
    </DrawerDirection>
  );
};

export default LezzooOrder;
