import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import ar from './locales/ar.json';
import kr from './locales/kr.json';
import { languageStorageName } from './configs/constants';
const langLocaleStorage = localStorage.getItem(languageStorageName);
const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  },
  kr: {
    translation: kr
  }
};

i18n
  // detect user language
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  .init({
    resources,
    fallbackLng: langLocaleStorage?.replaceAll('"', '') || 'en',
    debug: false,
    lng: langLocaleStorage?.replaceAll('"', '') || 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
