import { Button, Input, Modal, Row } from 'antd';
import styles from './ConfirmRefundModal.module.scss';
import { CloseCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ConfirmRefundModal = ({
  setPassword,
  onConfirm,
  onClose,
  loading
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open
      closeIcon={
        <CloseCircleFilled
          onClick={onClose}
          style={{
            color: '#7D8FA9',
            fontSize: 25,
            float: 'var(--right)'
          }}
        />
      }
      onCancel={onClose}
      footer={
        <Row className={styles.footer}>
          <Button onClick={onClose} style={{ background: '#E4E4E6' }}>
            {t('close')}
          </Button>
          <Button
            loading={loading}
            onClick={onConfirm}
            style={{ background: '#6DFF96' }}
          >
            {t('confirm')}
          </Button>
        </Row>
      }
    >
      <h2 className={styles.title}>{t('enter_passowrd')}</h2>
      <Input.Password
        className={styles.input}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
    </Modal>
  );
};
