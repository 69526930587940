import { useTranslation } from 'react-i18next';
import DrawerDirection from '../../../utils/DrawerDirection';
import { CloseCircleFilled } from '@ant-design/icons';
import style from './style/shiftDrawer.module.scss';
import { useEffect, useRef, useState } from 'react';
import BackspaceSvg from '../../../components.js/svg/BackspaceSvg';
import Calculator from './calculator/Calculator';
import { useAxiosPost } from '../../../configs/axios';
import { Input, Spin } from 'antd';
export default function ShiftDrawer({
  open,
  setShowStartShift,
  isStarted,
  getShiftData
}) {
  const { t } = useTranslation();
  const [startPrice, setStartPrice] = useState(0);
  const ref = useRef(null);

  const { request: requestForSend, loading: loadingForSend } = useAxiosPost(
    isStarted ? '/registers/end' : '/registers/start'
  );
  return (
    <DrawerDirection
      closeIcon={
        <CloseCircleFilled
          style={{
            color: '#7D8FA9',
            fontSize: 25,
            float: 'var(--right)'
          }}
        />
      }
      title={
        <p className="wh-clr">
          {isStarted ? t('close_shift') : t('start_a_new_shift')}
        </p>
      }
      open={open}
      width="50%"
      onClose={() => {
        setShowStartShift(false);
      }}
    >
      <Input.TextArea
        placeholder={t('note')}
        onChange={(v) => {
          ref.current = v.target.value;
        }}
      />

      <section className={style['shift']}>
        <p className="wh-clr">{t('enter_starting_balance')}</p>
        <Calculator
          lastPriceUpdate={(price) => {
            setStartPrice(price);
          }}
        />
        <button
          className={style['submit-shift']}
          onClick={() => {
            requestForSend({
              amount: startPrice,
              note: ref.current
            }).then(() => {
              setShowStartShift(false);
              ref.current = null;
              getShiftData();
            });
          }}
        >
          {loadingForSend ? (
            <Spin />
          ) : isStarted ? (
            t('end_shift')
          ) : (
            t('start_shift')
          )}
        </button>
      </section>
    </DrawerDirection>
  );
}
