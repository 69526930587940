export default function UserSvg({ color = 'var(--seventh-color)' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M20.5 21C20.5 19.6044 20.5 18.9067 20.3278 18.3389C19.94 17.0605 18.9395 16.06 17.6611 15.6722C17.0933 15.5 16.3956 15.5 15 15.5H10C8.60444 15.5 7.90665 15.5 7.33886 15.6722C6.06045 16.06 5.06004 17.0605 4.67224 18.3389C4.5 18.9067 4.5 19.6044 4.5 21M17 7.5C17 9.98528 14.9853 12 12.5 12C10.0147 12 8 9.98528 8 7.5C8 5.01472 10.0147 3 12.5 3C14.9853 3 17 5.01472 17 7.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
