import { createSlice } from '@reduxjs/toolkit';
import { OrderStatusesEnum } from '../configs/constants';

// Dummy data simulating API response
const initialState = {
  orders: [],
  lezzooOrdersCount: 0
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload.orders;
    },
    addToOrdersList: (state, action) => {
      state.orders = [...state.orders, action.payload];
    },

    updateOrder: (state, action) => {
      const { order_id, order_status, order_preparing_est_at } = action.payload;
      const orderIndex = state.orders.findIndex(
        (order) => order.order_id === order_id
      );
      if (orderIndex !== -1) {
        if (
          [
            OrderStatusesEnum.Preparing,
            OrderStatusesEnum.InTheKitchen
          ].includes(order_status)
        ) {
          state.orders[orderIndex].order_status = order_status;
          state.orders[orderIndex].order_preparing_est_at =
            order_status === OrderStatusesEnum.InTheKitchen
              ? order_preparing_est_at
              : new Date();
        }
      }
    },
    setLezzooOrdersCount: (state, action) => {
      state.lezzooOrdersCount = action.payload;
    },
    incrementLezzooOrdersCount: (state) => {
      state.lezzooOrdersCount += 1;
    },
    decrementLezzooOrdersCount: (state) => {
      state.lezzooOrdersCount -= 1;
    }
  }
});

export const lezzooOrdersCountSelector = (state) =>
  state.orders.lezzooOrdersCount;

export const {
  setOrders,
  addToOrdersList,
  updateOrder,
  setLezzooOrdersCount,
  incrementLezzooOrdersCount,
  decrementLezzooOrdersCount
} = ordersSlice.actions;
export default ordersSlice.reducer;
