import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import styles from './AllDayPanelDrawer.module.css'; // Import the module CSS
import { useTranslation } from 'react-i18next';

const getRandomColor = () => {
  const letters = '89ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const AllDayPanelDrawer = () => {
  const orders = useSelector((state) => state.orders.orders);
  const { t } = useTranslation();

  const productsWithAddons = [];
  const productsWithoutAddons = [];

  orders
    .filter((order) => !order.completed)
    .forEach((order) => {
      order.products.forEach((item) => {
        if (item.addons && item.addons.length > 0) {
          productsWithAddons.push(item);
        } else {
          productsWithoutAddons.push(item);
        }
      });
    });

  return (
    <div className={styles.container}>
      {productsWithAddons.length > 0 && (
        <div className={styles.section}>
          <Typography.Text className={styles.sectionTitle}>
            {t('withAddons')}:
          </Typography.Text>
          <ul className={styles.list}>
            {productsWithAddons.map((item, index) => (
              <li key={index} className={styles.listItem}>
                <div className={styles.product}>
                  <span className={styles.productName}>
                    {item.quantity}x {item.name}
                  </span>
                </div>
                <ul className={styles.addonList}>
                  {item.addons.map((addon, addonIndex) => (
                    <li key={addonIndex} className={styles.addonItem}>
                      <span
                        style={{
                          backgroundColor: getRandomColor(),
                          borderRadius: '50%',
                          display: 'inline-block',
                          width: '8px',
                          height: '8px',
                          marginRight: '10px'
                        }}
                      />
                      {addon.name} - {addon.price?.toLocaleString()} IQD
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}

      {productsWithoutAddons.length > 0 && (
        <div className={styles.section}>
          <Typography.Text className={styles.sectionTitle}>
            {t('withoutAddons')}:
          </Typography.Text>
          <ul className={styles.list}>
            {productsWithoutAddons.map((item, index) => (
              <li key={index} className={styles.listItem}>
                <span
                  style={{
                    backgroundColor: getRandomColor(),
                    borderRadius: '50%',
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    marginRight: '10px'
                  }}
                />
                {item.quantity}x {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AllDayPanelDrawer;
