import { Spin, Tag, Image, Row } from 'antd';
import debounceInput from '../function/debounceInput';
import { useEffect, useRef, useState } from 'react';
import { localStorageName } from '../configs/constants';
import useLocalStorage from '../configs/localStorage';

function DebounceSelect({
  fetchOptions,
  placeholder,
  onListClick,
  dataOfFetch = () => {}
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auth] = useLocalStorage(localStorageName, null);

  const saveSearchValueScan = useRef(null);
  const inputRef = useRef();

  const debounceChange = debounceInput((value) => {
    if (value) {
      setLoading(true);
      fetchOptions({ q: value, search: value }).then((newOptions) => {
        const isNumeric = /^\d+$/.test(value);

        if (isNumeric && newOptions?.length == 1) {
          setOptions([]);
          dataOfFetch(newOptions);
        } else {
          setOptions(newOptions);
          dataOfFetch([]);
        }
        setLoading(false);
        return;
      });
    }
    setOptions([]);
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key == 'Enter') {
        /**
         * because the scanner have enter at last digit for that reason I saved the number of barcode in ref till
         * reach the enter when entered I will search for this barcode.
         */
        if (saveSearchValueScan.current) {
          debounceChange(saveSearchValueScan.current);
          // Clear inputRef and saveSearch
          inputRef.current.value = '';
          saveSearchValueScan.current = null;
        }
      } else {
        saveSearchValueScan.current = `${saveSearchValueScan.current}${event.key}`;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="custom-debounce-select">
      <input
        style={{ width: '90%' }}
        // autoFocus
        ref={inputRef}
        placeholder={placeholder}
        onBlur={(e) => {
          e.target.value = '';
        }}
        onChange={(e) => {
          const value = e.target.value;
          debounceChange(value);
        }}
      />
      <div className="option-container">
        <ul>
          {loading && !options.length ? (
            <div
              style={{
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Spin />
            </div>
          ) : options.length ? (
            options?.map((op) => {
              return (
                <li
                  key={op.product_id}
                  onClick={() => {
                    setOptions([]);

                    return onListClick(op);
                  }}
                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  {op.product_image && (
                    <Image
                      height={22}
                      style={{ aspectRatio: 1 }}
                      src={
                        op.product_image ||
                        'https://lezzooeats-uploads.s3.us-east-2.amazonaws.com/custom/%3F.webp'
                      }
                    />
                  )}
                  {op?.name}{' '}
                  {op.barcode ? <Tag color="blue">{op.barcode}</Tag> : null}
                  {op.price ? (
                    <Tag color="orange">
                      {op.price} {auth?.user?.default_currency_code ?? 'IQD'}
                    </Tag>
                  ) : null}
                </li>
              );
            })
          ) : null}
        </ul>
      </div>
    </div>
  );
}

export default DebounceSelect;
