import { message, Modal, TimePicker, Button, Row, InputNumber } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useAxiosPut } from '../../configs/axios';

const PreparationModal = ({isModalVisible, order_id, refresh, handleModalCancel }) => {
  const [preparingEstTime, setPreparingEstTime] = useState(null);
  const [showCustomPicker, setShowCustomPicker] = useState(false); 
  const [activeButton, setActiveButton] = useState(null); 

  const { request: addPreparationTime, loading: addPreparationTimeLoading } =
    useAxiosPut(`/kds/addPreparationTime/${order_id}`);

  const handleModalOk = () => {
    if (preparingEstTime) {
      submitPreparationTime(preparingEstTime);
    } else {
      message.error('Please select a preparation estimate time!');
    }
  };

  const submitPreparationTime = (time) => {
    
    addPreparationTime({
      prepTime:time
    }).then(() => {
      refresh();
      message.success('Order preparing estimate time updated successfully');
      handleModalCancel();
    });
  };

  const handlePresetTime = (minutes) => {
    setPreparingEstTime(minutes);
    setActiveButton(minutes); 
    submitPreparationTime(minutes);
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      confirmLoading={addPreparationTimeLoading}
    >
      <h2 style={{ marginBottom: 20, color:'#fff' }}>Select Preparation Time:</h2>

      {!showCustomPicker && (
        <Row justify={'space-evenly'}>
          <Button
            onClick={() => handlePresetTime(5)}
            type={activeButton === 5 ? 'primary' : 'default'} 
          >
            5 Min
          </Button>
          <Button
            onClick={() => handlePresetTime(10)}
            type={activeButton === 10 ? 'primary' : 'default'} 
          >
            10 Min
          </Button>
          <Button
            onClick={() => handlePresetTime(15)}
            type={activeButton === 15 ? 'primary' : 'default'} 
          >
            15 Min
          </Button>
          <Button
            onClick={() => handlePresetTime(20)}
            type={activeButton === 20 ? 'primary' : 'default'} 
          >
            20 Min
          </Button>
        </Row>
      )}

      <Button
        style={{ marginTop: 20, width: '100%' }}
        onClick={() => setShowCustomPicker((prev) => !prev)}
      >
        {showCustomPicker ? 'Cancel Custom Time' : 'Select Custom Time'}
      </Button>

      {showCustomPicker && (
        <>
          <InputNumber
            onChange={(value) => {
              if (value) {
                setPreparingEstTime(value);
              }
            }}
            style={{ width: '100%', marginTop: 20 }}
          />
          <Button
            type="primary"
            style={{ marginTop: 20, width: '100%' }}
            onClick={handleModalOk}
          >
            Submit Custom Time
          </Button>
        </>
      )}
    </Modal>
  );
};

export default PreparationModal;
