import { RightOutlined } from '@ant-design/icons';
import {
  Row,
  Space,
  Image,
  Form,
  Col,
  Select,
  InputNumber,
  Button,
  Popover,
  notification
} from 'antd';
import GiftSvg from '../../../components.js/svg/GiftSvg';
import PercentageSvg from '../../../components.js/svg/PercentageSvg';
import TrashSvg from '../../../components.js/svg/TrashSvg';
import { useTranslation } from 'react-i18next';
import { useReducer } from 'react';
import DetailProduct from '../../../components.js/checkoutPage/cashier/DetailProduct';

export default function SingleSelectedCard({ product, style, dispatch }) {
  const { t } = useTranslation();
  const [formDiscount] = Form.useForm();

  const initialState = {
    openDiscountPopover: false,
    openGiftPopover: false,
    openDetailDrawer: false,
    singleCard: {}
  };

  const [statesSelectedCard, changeStates] = useReducer(
    (currentVal, newVal) => ({
      ...currentVal,
      ...newVal
    }),
    initialState
  );
  return (
    <div className={style['single-card']} key={product.product_id}>
      <Row
        justify="space-between"
        wrap="nowrap"
        className={style['single-card-header']}
      >
        <Space>
          <Image
            className={style['single-card-img']}
            src={
              product.product_image ||
              'https://lezzooeats-uploads.s3.us-east-2.amazonaws.com/custom/%3F.webp'
            }
          />
          <Space direction="vertical">
            <p>{product.product_name}</p>
            <span>{product.price?.toLocaleString()} IQD</span>
          </Space>
        </Space>
        <Space>
          <button
            onClick={() => {
              dispatch({
                type: 'DECREASE_QTY',
                product: product
              });
            }}
          >
            {product.quantity == 1 ? <TrashSvg /> : '-'}
          </button>
          <p>{product.quantity}</p>
          <button
            onClick={() => {
              dispatch({
                type: 'INCREASE_QTY',
                product: product
              });
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.75V14.25M3.75 9H14.25"
                stroke="#1F1D2B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Space>
      </Row>
      <Row
        justify={'start'}
        align={'middle'}
        style={{ gap: '.5rem', flexDirection: 'row', alignItems: 'center' }}
      >
        <Col sm={1}>
          <label style={{ color: 'white', fontSize: '1.5rem' }}>x</label>
        </Col>
        <Col sm={20} lg={5}>
          <input
            style={{ outline: 'none', width: '100%' }}
            onChange={(e) => {
              if (e.target.value < 1) {
                e.target.value = 1;
              }
              dispatch({
                type: 'SET_QUANTITY',
                product: product,
                quantity: +e.target.value
              });
            }}
            min={1}
            type="number"
            value={product.quantity}
          />
        </Col>
      </Row>
      <Row className={style['single-card-footer']} justify="space-between">
        <Space>
          <Popover
            open={statesSelectedCard.openGiftPopover}
            onOpenChange={() => {
              changeStates({
                openGiftPopover: !statesSelectedCard.openGiftPopover
              });
            }}
            content={
              <Form
                key={product?.numberGift} // we re render if the number of gift greater than quantity
                layout="vertical"
                onFinish={(values) => {
                  changeStates({
                    openGiftPopover: false
                  });
                  dispatch({
                    type: 'SET_GIFT_PER_PRODUCT',
                    product: product,
                    numberGift: values.numberGift
                  });
                }}
              >
                <Row
                  style={{
                    width: '150px',
                    height: '80px'
                  }}
                >
                  <Col span={24}>
                    <Form.Item
                      name="numberGift"
                      noStyle
                      initialValue={product?.numberGift || 0}
                    >
                      <InputNumber
                        min={0}
                        max={product.quantity}
                        placeholder={t('amount')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} offset={15}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        style={{
                          background: 'var(--light-green-color)',
                          borderWidth: 0,
                          marginTop: 10
                        }}
                      >
                        {t('save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
            trigger="click"
          >
            <button
              style={{
                backgroundColor: product?.numberGift
                  ? 'var(--light-green-color)'
                  : 'var(--white-color)'
              }}
            >
              <GiftSvg color="var(--main-color)" />
              {product?.numberGift
                ? `${product?.numberGift}/${product?.quantity} `
                : null}
              {t('gift')}
            </button>
          </Popover>
          <Popover
            open={statesSelectedCard.openDiscountPopover}
            onOpenChange={() => {
              changeStates({
                openDiscountPopover: !statesSelectedCard.openDiscountPopover
              });
            }}
            content={
              <Form
                form={formDiscount}
                layout="vertical"
                onFinish={(values) => {
                  if (values.discount_type) {
                    changeStates({
                      openDiscountPopover: false
                    });
                    dispatch({
                      type: 'SET_DISCOUNT_PER_PRODUCT',
                      product,
                      discountValue: values.discount_value,
                      discountType: values.discount_type
                    });
                  } else {
                    notification.warning({
                      message: 'Please Select Discount Type'
                    });
                  }
                }}
              >
                <Row
                  style={{
                    width: '150px',
                    height: '120px'
                  }}
                >
                  <Col span={24}>
                    <Form.Item name="discount_type" noStyle>
                      <Select
                        style={{
                          width: '100%'
                        }}
                        placeholder="Discount type"
                        options={[
                          {
                            label: t('percentage_discount'),
                            value: 'percentage'
                          },
                          {
                            label: t('fixed_discount'),
                            value: 'fixed'
                          }
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      margin: '10px 0'
                    }}
                  >
                    <Form.Item
                      name="discount_value"
                      noStyle
                      rules={[
                        {
                          message: t('required'),
                          required: true
                        },
                        {
                          validator: (_, value) => {
                            return new Promise((resolve, reject) => {
                              const type =
                                formDiscount.getFieldValue('discount_type');
                              if (!type) {
                                notification.warning({
                                  message: t('please_select_discount_type')
                                });
                                reject(new Error());
                              } else if (
                                type === 'fixed' &&
                                value < product.price
                              ) {
                                resolve();
                              } else if (
                                type === 'percentage' &&
                                value <= 100
                              ) {
                                resolve();
                              } else {
                                notification.warning({
                                  message: `${t('maximum')} ${
                                    type === 'fixed'
                                      ? product?.price?.toLocaleString()
                                      : '100%'
                                  }`
                                });
                                reject();
                              }
                            });
                          }
                        }
                      ]}
                    >
                      <InputNumber min={0} placeholder={t('amount')} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        style={{
                          background: 'var(--light-green-color)',
                          borderWidth: 0,
                          width: '100%'
                        }}
                      >
                        {t('save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
            trigger="click"
          >
            <button>
              <PercentageSvg color="var(--main-color)" />
              {t('discount')}
            </button>
          </Popover>
        </Space>
        <button
          className={style['detail-btn']}
          onClick={() => {
            changeStates({
              openDetailDrawer: true,
              singleCard: product
            });
          }}
        >
          {t('details')}
          <RightOutlined />
        </button>
      </Row>
      {statesSelectedCard.openDetailDrawer ? (
        <DetailProduct
          singleProduct={statesSelectedCard.singleCard}
          open={statesSelectedCard.openDetailDrawer}
          onDrawerClose={() => {
            changeStates({
              openDetailDrawer: false,
              singleCard: {}
            });
          }}
        />
      ) : null}
    </div>
  );
}
