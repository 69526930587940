import JsBarcode from 'jsbarcode';
import { handlePrintClick } from '../../function/printOnClick';

export default function barcodeTemplate({
  barcode,
  showPrice,
  barCodeRef,
  price = 0,
  defaultCurrency = 'IQD'
}) {
  JsBarcode(barCodeRef.current, barcode, {
    format: 'CODE128'
  });

  setTimeout(() => {
    const svgContent = new XMLSerializer().serializeToString(
      barCodeRef.current
    );
    const bb = `
                        <style>
                          svg{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                          }
                          p{
                            text-align: center; 
                            font-weight: bold;
                            margin: 0; 
                            padding:0
                          }
                        </style>
                        <div style="width: 4cm; height: 2.5cm; display: flex;
                        flex-direction: column;">
                        ${
                          showPrice && price
                            ? `<p>
                            ${price?.toLocaleString()} ${defaultCurrency}
                          <p>`
                            : ''
                        }
                          ${svgContent}
                        </div>
                        `;

    // const win = window.open(
    //   '',
    //   'Title',
    //   'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=600,'
    // );

    // win.document.body.innerHTML = bb;

    // setTimeout(() => {
    //   win.print();
    // }, 2000);
    handlePrintClick(bb);
  }, 1000);
}
