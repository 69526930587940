import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseCircleFilled,
  RiseOutlined,
  FallOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { useAxiosGet, useAxiosPatch } from '../../../configs/axios';
import { Fragment, useEffect, useReducer, useRef } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form';
import RemoteSelectForm from '../../../utils/RemoteSelectForm';
import {
  dateTimeFormat12,
  languages,
  localStorageName
} from '../../../configs/constants';
import useLocalStorage from '../../../configs/localStorage';
import barcodeTemplate from '../../print/templateBarcode';

function DetailProduct({ singleProduct, open, onDrawerClose }) {
  const barCodeRef = useRef(null);
  const [auth] = useLocalStorage(localStorageName, null);
  const { t, i18n } = useTranslation();
  const [form] = useForm();

  const initialState = {
    barcodeGenerator: '',
    pagination: {
      pageSize: 10,
      current: 1,
      total: 0,
      showSizeChanger: false,
      hideOnSinglePage: false
    }
  };

  const [states, changeStates] = useReducer(
    (currentVal, newVal) => ({
      ...currentVal,
      ...newVal
    }),
    initialState
  );

  const {
    data: dataTransaction,
    loading: loadingTransaction,
    request: getTransaction
  } = useAxiosGet(
    `/products/product_sku_transaction/${singleProduct?.product_id}?pageSize=10`,
    {
      autoRun: true,
      page: 0
    }
  );

  const { data: dataDetail, request: requestDataDetail } = useAxiosGet(
    `/products/detail/${singleProduct?.product_id}`,
    {}
  );

  const { request: updateRequest } = useAxiosPatch(
    '/products/update_products_and_sku'
  );

  function handleTableChange(pag) {
    states.pagination.current = pag.current;
    states.pagination.pageSize = pag.pageSize;
    changeStates({ pagination: pag });
    getTransaction({ autoRun: true, page: pag.current - 1 });
  }

  function onFinish(values) {
    const buyingPrice = form.getFieldValue('buying_price'); // because maybe not render the input for that reason the value not saving in (values)

    if (values.barcode) {
      const descriptions = languages.map((a) => {
        return {
          language: a.lang,
          title: values[`name_${a.lang}`],
          description: values[`desc_${a.lang}`]
        };
      });
      const body = {
        sellingPrice: values.selling_price,
        buyingPrice: buyingPrice,
        skuId: dataDetail?.sku_id,
        productId: singleProduct?.product_id,
        name: values.name_en,
        category_id: values.category_id?.value,
        barcode: values.barcode,
        descriptions
      };

      updateRequest(body).then(() => {
        notification.success({
          message: t('successfully')
        });
      });
    } else {
      notification.warning({
        message: t('please_generate_barcode')
      });
    }
  }

  const columns = [
    {
      title: t('transactionType'),
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      render: (val) => {
        if (val == 'out') {
          return (
            <Fragment>
              <Tag color="orange">{val}</Tag>
              <RiseOutlined />
            </Fragment>
          );
        }
        return (
          <Fragment>
            <Tag color="green">{val}</Tag>
            <FallOutlined />
          </Fragment>
        );
      }
    },
    {
      title: t('name'),
      dataIndex: 'user_name',
      key: 'user_name'
    },
    {
      title: t('orderId'),
      dataIndex: 'order_id',
      key: 'order_id',
      sorter: (a, b) => a.order_id - b.order_id
    },
    {
      title: t('reasonCode'),
      dataIndex: 'reason_code',
      key: 'reason_code'
    },
    {
      title: t('amount'),
      dataIndex: 'base_transaction_amount',
      key: 'base_transaction_amount'
    },
    {
      title: t('date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (val) => dayjs(val).format(dateTimeFormat12)
    }
  ];

  useEffect(() => {
    requestDataDetail().then((res) => {
      form.setFieldsValue({
        lezzoo_price: res.lezzoo_price,
        buying_price: res.buying_price,
        selling_price: res.price,
        barcode: res.barcode,
        name: res.name,
        category_id: {
          value: res.category_id,
          label: res.category_name
        }
      });

      res?.descriptions?.forEach((desc) => {
        form.setFieldsValue({
          [`name_${desc.language}`]: desc.name,
          [`desc_${desc.language}`]: desc.description
        });
      });
    });
  }, []);

  return (
    <Drawer
      placement={i18n.language == 'en' ? 'right' : 'left'}
      closable={true}
      onClose={onDrawerClose}
      open={open}
      key={'right'}
      width={'80%'}
      closeIcon={
        <CloseCircleFilled
          style={{
            color: '#7D8FA9',
            fontSize: 25
          }}
        />
      }
      style={{ backgroundColor: 'var(--bg-drawer)', paddingBottom: '5%' }}
      destroyOnClose
    >
      <Row gutter={[20, 20]}>
        <Col span={18}>
          <Space>
            <Image
              src={
                dataDetail?.product_image ||
                'https://lezzooeats-uploads.s3.us-east-2.amazonaws.com/custom/%3F.webp'
              }
              height={100}
              width={100}
              style={{
                borderRadius: 10
              }}
            />
            <Space direction="vertical">
              <p
                className="wh-clr"
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                {' '}
                <span
                  style={{
                    color: 'var(--light-green-color)'
                  }}
                >
                  {t('name')}
                </span>{' '}
                : {dataDetail?.product_name}
              </p>
              <p
                className="wh-clr"
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                <span
                  style={{
                    color: 'var(--light-green-color)'
                  }}
                >
                  {t('category')}
                </span>{' '}
                : {dataDetail?.category_name}
              </p>
              <p
                className="wh-clr"
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                <span
                  style={{
                    color: 'var(--light-green-color)'
                  }}
                >
                  {t('price')}
                </span>{' '}
                : {dataDetail?.price?.toLocaleString()} {dataDetail?.currency}
              </p>
              <p
                className="wh-clr"
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                <span
                  style={{
                    color: 'var(--light-green-color)'
                  }}
                >
                  {t('created_at')}
                </span>{' '}
                : {dayjs(dataDetail?.created_at).format(dateTimeFormat12)}
              </p>
            </Space>
          </Space>
        </Col>
        <Col span={6}>
          <Typography.Title
            style={{
              color: 'var(--white-color)'
            }}
          >
            QTY: {dataDetail?.stock_amount}
          </Typography.Title>
        </Col>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="form-configure"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <Row gutter={[20, 20]}>
            {languages.map((lang) => (
              <Col span={6} key={lang.id}>
                <Form.Item
                  name={`name_${lang.lang}`}
                  label={t(`name_${lang.lang}`)}
                  rules={[
                    {
                      required: true,
                      message: t('required')
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}
            {languages.map((lang) => (
              <Col span={6} key={lang.id}>
                <Form.Item
                  name={`desc_${lang.lang}`}
                  label={t(`desc_${lang.lang}`)}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}
            <Col span={6}>
              <RemoteSelectForm
                labelForm={t('category')}
                nameForm="category_id"
                allowClear
                filterFromServer={false}
                endpoint="/categories/list"
                valueOption="category_id"
                labelOption="name"
                query={{
                  limit: 100,
                  offset: 0
                }}
              />
            </Col>
            <Col span={10}>
              <Space.Compact
                style={{
                  width: '100%'
                }}
              >
                <Form.Item label={t('barcode')} name="barcode">
                  <Input />
                </Form.Item>
                <Button
                  onClick={() => {
                    const productId = singleProduct?.product_id;
                    const now = Date.now() * Math.random();
                    const barcode = `${productId}${now}`.substring(0, 13);
                    form.setFieldValue('barcode', barcode);
                  }}
                  style={{
                    backgroundColor: 'var(--light-green-color)',
                    marginTop: 30
                  }}
                >
                  Generate
                </Button>
                <Popconfirm
                  title={t('with Price?')}
                  onConfirm={() => {
                    barcodeTemplate({
                      barcode: form.getFieldValue('barcode'),
                      showPrice: true,
                      barCodeRef,
                      price: form.getFieldValue('selling_price'),
                      defaultCurrency: auth?.user?.default_currency_code
                    });
                  }}
                  onCancel={() => {
                    barcodeTemplate({
                      barcode: form.getFieldValue('barcode'),
                      showPrice: false,
                      barCodeRef
                    });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{
                      backgroundColor: 'var(--light-green-color)',
                      marginTop: 30
                    }}
                  >
                    <CopyOutlined style={{ color: 'var(--black-color)' }} />
                  </Button>
                </Popconfirm>
              </Space.Compact>
            </Col>
            <svg ref={barCodeRef} style={{ display: 'none !important' }} />
            {!auth?.permissions?.some((per) => per?.permission_id == 16) ? (
              <Col span={6}>
                <Form.Item
                  label={t('buying_price')}
                  name="buying_price"
                  rules={[
                    {
                      message: t('required'),
                      required: true
                    }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={6}>
              <Form.Item
                label={t('selling_price')}
                name="selling_price"
                rules={[
                  {
                    message: t('required'),
                    required: true
                  }
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col offset={18} span={6}>
              <Button
                style={{
                  backgroundColor: 'var(--light-green-color)',
                  borderRadius: 8,
                  float: 'var(--right)',
                  width: '100%',
                  textAlign: 'center'
                }}
                htmlType="submit"
                // loading={createSKULoading || updateSKULoading}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>

      <Table
        dataSource={dataTransaction?.data || []}
        columns={columns}
        style={{ marginTop: 20 }}
        rowKey="transaction_id"
        loading={loadingTransaction}
        onChange={handleTableChange}
        pagination={{
          ...states.pagination,
          total: dataTransaction?.records
        }}
      />
    </Drawer>
  );
}

export default DetailProduct;
