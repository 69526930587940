import { Col, Row, Spin } from 'antd';
import DebounceSelect from '../../../utils/DebounceSelect';
import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { useTranslation } from 'react-i18next';
import { useAxiosGet } from '../../../configs/axios';
import { Fragment, useEffect, useState } from 'react';
import StartShiftSvg from '../../../components.js/svg/StartShiftSvg';
import ShiftDrawer from './ShiftDrawer';
import CloseShiftSvg from '../../../components.js/svg/CloseShiftSvg';
import { localStorageName } from '../../../configs/constants';
import useLocalStorage from '../../../configs/localStorage';

export default function MainPart({
  states,
  dispatch,
  isGrocery,
  setActiveTab
}) {
  const { t } = useTranslation();
  const [auth] = useLocalStorage(localStorageName);

  const vendorInfo = auth?.user;

  const [showStartShift, setShowStartShift] = useState(false);

  const [tabs] = useState(Array.from({ length: 12 }, (_, i) => i + 1));

  const [selectedTab, setSelectedTab] = useState(1);

  const { request: fetchProductListInInput } = useAxiosGet(
    '/products/list?limit=20&offset=0'
  );

  const { data: dataRegister, request: getShiftData } = useAxiosGet(
    '/registers',
    {
      autoRun: isGrocery
    }
  );

  const {
    request: fetchProducts,
    data: dataProducts,
    loading: dataLoading,
    lastParams
  } = useAxiosGet('/products/list?limit=20&offset=0');

  const { data: categories, request: getCategories } = useAxiosGet(
    '/categories/list',
    {
      limit: 100,
      offset: 0,
      parentId: null
    }
  );

  useEffect(() => {
    setActiveTab(selectedTab);
  }, [selectedTab]);

  const fetchProductsForCategories = async (
    categories,
    index,
    fetchProducts
  ) => {
    if (index >= categories.length) return; // Base case: stop recursion when all categories are processed

    const categoryId = categories[index].category_id;
    const response = await fetchProducts({ categoryId });

    // If no products found for the current category and there are more categories to process
    if (response?.length === 0 && index + 1 < categories.length) {
      await fetchProductsForCategories(categories, index + 1, fetchProducts);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const categories = await getCategories();
      if (categories?.length) {
        await fetchProductsForCategories(categories, 0, fetchProducts);
      }
    };

    fetchData();
  }, []);
  return (
    <Fragment>
      <div style={{ height: '100%' }} className={style['main-part']}>
        <Row gutter={[20, 20]}>
          <Col sm={24} lg={18}>
            <DebounceSelect
              fetchOptions={fetchProductListInInput}
              optionValue="product_id"
              placeholder={t('search_by_barcode_or_product_name')}
              onListClick={(prod) => {
                if (prod) {
                  dispatch({
                    type: 'ADD_CART',
                    product: prod
                  });
                }
              }}
              dataOfFetch={(data) => {
                if (data?.length) {
                  dispatch({
                    type: 'ADD_CART',
                    product: data[0]
                  });
                }
              }}
            />
          </Col>
          <Col sm={24} lg={6}>
            <button
              className={style['btn-start-end-shift']}
              onClick={() => {
                setShowStartShift(true);
              }}
            >
              {Object.keys(dataRegister || {})?.length ? (
                <Fragment>
                  <CloseShiftSvg />
                  {t('close_shift')}
                </Fragment>
              ) : (
                <Fragment>
                  <StartShiftSvg />
                  {t('start_shift')}
                </Fragment>
              )}
            </button>
          </Col>

          {/* Only show categories for store view */}
          <Col style={{ display: isGrocery ? 'none' : 'block' }} span={24}>
            <div className={style['category']}>
              {categories?.map((cat) => {
                return (
                  <button
                    key={cat.category_id}
                    className={
                      lastParams?.categoryId == cat.category_id
                        ? style['active']
                        : ''
                    }
                    onClick={() => {
                      fetchProducts({
                        categoryId: cat.category_id
                      });
                    }}
                  >
                    {cat?.name}
                  </button>
                );
              })}
            </div>
          </Col>

          {/* Only show products for store view */}
          <Col style={{ display: isGrocery ? 'none' : 'block' }} span={24}>
            {dataLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '50vh',
                  alignItems: 'center'
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div
                className={style['grid-product']}
                style={{
                  '--max-fr': dataProducts?.length > 6 ? '1fr' : '250px'
                }}
              >
                {dataProducts?.length > 0 ? (
                  dataProducts.map((prod) => {
                    const isContainId = Object.keys(states.items).includes(
                      prod.product_id.toString()
                    );

                    return (
                      <div
                        className={`${style['single-product']} ${
                          isContainId ? style['active-single-product'] : ''
                        }`}
                        key={prod.product_id}
                        onClick={() => {
                          dispatch({
                            type: 'ADD_CART',
                            product: prod
                          });
                        }}
                      >
                        <img
                          src={
                            prod.product_image ||
                            'https://lezzooeats-uploads.s3.us-east-2.amazonaws.com/custom/%3F.webp'
                          }
                        />
                        <div className={style['detail-product']}>
                          <span>
                            {prod?.price.toLocaleString() + ' '}
                            {vendorInfo?.default_currency_code || 'IQD'}
                          </span>
                          <p>{prod?.name || prod.product_name}</p>
                          <button>
                            {isContainId
                              ? states?.items[prod.product_id]?.quantity
                              : '+'}
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      minHeight: '50vh',
                      alignItems: 'center',
                      overflow: 'hidden'
                    }}
                  >
                    <h1
                      style={{
                        textAlign: 'center',
                        color: '#fff',
                        fontSize: '32px'
                      }}
                    >
                      {t('no_items_found')}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </Col>

          <Row
            style={{ display: isGrocery ? 'flex' : 'none' }}
            className={style['market-tabs-parent']}
          >
            {tabs.map((tab) => {
              return (
                <Col
                  sm={16}
                  lg={6}
                  onClick={() => setSelectedTab(tab)}
                  style={{
                    backgroundColor:
                      tab === selectedTab ? 'var(--red-color)' : 'white',
                    borderRadius: '.5rem',
                    padding: '5rem',
                    cursor: 'pointer',
                    color: selectedTab === tab ? 'white' : 'var(--main-color)',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    transition: 'all .2s',
                    textAlign: 'center'
                  }}
                  key={tab}
                >
                  {t('tab')} {tab}
                </Col>
              );
            })}
          </Row>
        </Row>
      </div>
      {showStartShift ? (
        <ShiftDrawer
          open={showStartShift}
          setShowStartShift={setShowStartShift}
          isStarted={!!Object.keys(dataRegister || {})?.length}
          getShiftData={getShiftData}
        />
      ) : null}
    </Fragment>
  );
}
