export default function StartShiftSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
    >
      <g clipPath="url(#clip0_3858_8785)">
        <path
          d="M14.5 27.6821C13.1212 27.6848 11.7555 27.4052 10.4814 26.8595C9.20725 26.3138 8.04982 25.5126 7.0756 24.5021C6.99203 24.4185 6.92537 24.3185 6.87951 24.2079C6.83365 24.0974 6.80951 23.9784 6.8085 23.8581C6.8075 23.7378 6.82963 23.6185 6.87363 23.5071C6.91763 23.3957 6.9826 23.2945 7.06475 23.2094C7.14691 23.1243 7.2446 23.057 7.35214 23.0115C7.45967 22.9659 7.57489 22.943 7.69107 22.944C7.80726 22.9451 7.92207 22.9701 8.02883 23.0176C8.13558 23.0651 8.23213 23.1341 8.31285 23.2207C9.53655 24.4881 11.0957 25.3512 12.793 25.7009C14.4903 26.0506 16.2497 25.8712 17.8485 25.1852C19.4474 24.4993 20.814 23.3378 21.7754 21.8474C22.7369 20.3571 23.2501 18.605 23.2501 16.8126C23.2501 15.0202 22.7369 13.268 21.7754 11.7777C20.814 10.2874 19.4474 9.1258 17.8485 8.43989C16.2497 7.75398 14.4903 7.57452 12.793 7.92421C11.0957 8.2739 9.53655 9.13704 8.31285 10.4045C8.23213 10.491 8.13558 10.5601 8.02883 10.6076C7.92207 10.6551 7.80726 10.6801 7.69107 10.6811C7.57489 10.6822 7.45967 10.6592 7.35214 10.6137C7.2446 10.5681 7.14691 10.5008 7.06475 10.4157C6.9826 10.3306 6.91763 10.2294 6.87363 10.1181C6.82963 10.0067 6.8075 9.88735 6.8085 9.76701C6.80951 9.64668 6.83365 9.52777 6.87951 9.4172C6.92537 9.30663 6.99203 9.20663 7.0756 9.12303C8.29608 7.85709 9.79927 6.92254 11.4519 6.40223C13.1045 5.88192 14.8556 5.79192 16.5499 6.1402C18.2441 6.48849 19.8292 7.26429 21.1648 8.39886C22.5003 9.53344 23.5449 10.9917 24.2061 12.6445C24.8672 14.2972 25.1245 16.0934 24.9551 17.8738C24.7857 19.6541 24.1948 21.3637 23.2348 22.851C22.2749 24.3383 20.9755 25.5573 19.4518 26.4C17.9281 27.2427 16.2273 27.6831 14.5 27.6821Z"
          fill="#1E2633"
        />
        <path
          d="M18.875 22.25C18.643 22.2499 18.4204 22.1544 18.2564 21.9845L13.8814 17.4532C13.7173 17.2833 13.625 17.0528 13.625 16.8125V10.4688C13.625 10.2284 13.7172 9.99789 13.8813 9.82793C14.0454 9.65798 14.2679 9.5625 14.5 9.5625C14.7321 9.5625 14.9546 9.65798 15.1187 9.82793C15.2828 9.99789 15.375 10.2284 15.375 10.4688V16.4373L19.4936 20.703C19.616 20.8298 19.6993 20.9912 19.733 21.167C19.7668 21.3428 19.7494 21.525 19.6832 21.6905C19.617 21.8561 19.5049 21.9976 19.361 22.0972C19.2172 22.1968 19.048 22.25 18.875 22.25Z"
          fill="#1E2633"
        />
        <path
          d="M11 17.7188H3.125C2.89294 17.7188 2.67038 17.6233 2.50628 17.4533C2.34219 17.2834 2.25 17.0529 2.25 16.8125C2.25 16.5721 2.34219 16.3416 2.50628 16.1717C2.67038 16.0017 2.89294 15.9062 3.125 15.9062H11C11.2321 15.9062 11.4546 16.0017 11.6187 16.1717C11.7828 16.3416 11.875 16.5721 11.875 16.8125C11.875 17.0529 11.7828 17.2834 11.6187 17.4533C11.4546 17.6233 11.2321 17.7188 11 17.7188Z"
          fill="#2FEC64"
        />
        <path
          d="M8.37506 20.4376C8.20203 20.4376 8.03289 20.3844 7.88903 20.2848C7.74517 20.1852 7.63305 20.0437 7.56684 19.8781C7.50063 19.7126 7.4833 19.5304 7.51705 19.3546C7.55079 19.1788 7.6341 19.0174 7.75643 18.8906L9.76281 16.8126L7.75643 14.7346C7.59704 14.5636 7.50885 14.3347 7.51084 14.0971C7.51284 13.8595 7.60486 13.6322 7.76709 13.4642C7.92932 13.2961 8.14878 13.2008 8.37821 13.1988C8.60763 13.1967 8.82866 13.288 8.99368 13.4531L11.6187 16.1719C11.7827 16.3418 11.8749 16.5723 11.8749 16.8126C11.8749 17.0529 11.7827 17.2834 11.6187 17.4533L8.99368 20.1721C8.82963 20.342 8.6071 20.4375 8.37506 20.4376Z"
          fill="#2FEC64"
        />
      </g>
      <defs>
        <clipPath id="clip0_3858_8785">
          <rect
            width="28"
            height="29"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
