import { io } from 'socket.io-client';
import { API_SOCKET, localStorageName } from '../configs/constants';
import useLocalStorage from '../configs/localStorage';
import { useRef } from 'react';
import { useEffect } from 'react';

const useSocket = () => {
  const [auth] = useLocalStorage(localStorageName, null);

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(API_SOCKET, {
      query: {
        userId: auth?.user?.userId,
        userName: auth?.user?.user_name
      },
      reconnection: true,
      autoConnect: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity
    });

    socket.current.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    socket.current.emit(
      'join',
      `vendor:${auth?.user?.vendor_id}:orderChannel`,
      (response) => {
        if (response.status === 'ok') {
          console.log(
            `Successfully joined channel: vendor:${auth?.user?.vendor_id}:orderChannel`
          );
        } else {
          console.error('Failed to join channel:', response.message);
        }
      }
    );
    socket.current.emit(
      'join',
      `vendor:${auth?.user?.vendor_id}:orderStatusChannel`
    );
    socket.current.emit('join', `vendor:${auth?.user?.vendor_id}:cartChannel`);

    return () => {
      socket.current.emit('leave_room', {
        room: `vendor:${auth?.user?.vendor_id}:orderChannel`
      });
      socket.current.emit('leave_room', {
        room: `vendor:${auth?.user?.vendor_id}:orderStatusChannel`
      });
      socket.current.emit('leave_room', {
        room: `vendor:${auth?.user?.vendor_id}:cartChannel`
      });
      socket.current.disconnect();
    };
  }, [auth?.user?.vendor_id]);

  return socket.current;
};

export default useSocket;
