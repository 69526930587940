import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { uid } from 'uid';

const getCartId = (table_number, order_type) =>
  order_type == 'dinein' ? `${table_number}_${order_type}` : uid(16);

const validateCartExistence = (state, payload, cartId) => {
  const cart_id = cartId
    ? cartId
    : getCartId(payload.table_number, payload.order_type);
  if (state.carts && state.carts[cart_id]) {
    return true;
  }
  return false;
};

const createProductIndex = (item) => {
  try {
    const addonIds = [];
    const productId = item.product_id;

    if (Array.isArray(item.addons) && item.addons.length > 0) {
      item.addons.forEach((addon) => {
        addonIds.push(addon.addon_id);
      });
    } else if (item.addons && Object.keys(item.addons).length > 0) {
      Object.keys(item.addons).forEach((key) => {
        addonIds.push(key);
      });
    }

    if (addonIds.length === 0) {
      return `${productId}`;
    }

    // sort addonIds to make sure the index is consistent
    addonIds.sort();

    return `${productId}_${addonIds.join('_')}`;
  } catch (error) {
    if (addonIds.length === 0) {
      return `${productId}`;
    }
  }
};

const calculateDiscount = (total, discountType, discountAmount) => {
  if (discountType == 'fixed') {
    return parseFloat(discountAmount);
  }
  return parseFloat(parseFloat(total * (discountAmount / 100)));
};

const calculateCart = (cart) => {
  let cart_total = 0;
  let cart_subtotal = 0;
  let cart_count = 0;
  Object.keys(cart).forEach((key) => {
    const item = cart[key];
    cart_total += parseFloat(item.product_total_price);
    cart_subtotal += parseFloat(item.product_total_price);
    cart_count += parseInt(item.quantity);
  });

  return { new_cart_items: cart, cart_count, cart_total, cart_subtotal };
};

export const getProductPriceData = (item) => {
  let product_plain_price = 0;
  let product_single_price = 0;
  let product_total_price = 0;
  let product_quantity = 0;

  const product_index = createProductIndex(item);
  product_quantity = parseInt(item.quantity);
  product_plain_price += parseFloat(item.product_price);
  product_single_price += parseFloat(item.product_price);
  product_total_price += parseFloat(item.product_price);
  if (Array.isArray(item.addons) && item.addons.length > 0) {
    item.addons.forEach((addon) => {
      product_single_price += parseFloat(addon.addon_price);
      product_total_price += parseFloat(addon.addon_price);
    });
  } else if (item.addons && Object.keys(item.addons).length > 0) {
    Object.keys(item.addons).forEach((key) => {
      const addon = item.addons[key];
      product_single_price += parseFloat(addon.addon_price);
      product_total_price += parseFloat(addon.addon_price);
    });
  }
  product_total_price = parseFloat(
    product_total_price * parseFloat(product_quantity)
  );
  return {
    product_plain_price,
    product_single_price,
    product_total_price,
    product_index: product_index,
    quantity: product_quantity
  };
};

const getProductData = (item, cartItems) => {
  let product_plain_price = 0;
  let product_single_price = 0;
  let product_total_price = 0;
  let product_quantity = 0;
  const product_index = createProductIndex(item);
  if (cartItems[product_index]) {
    if (item.action_type == 'insert' || item.action_type == 'add') {
      product_quantity =
        parseInt(item.quantity) + parseInt(cartItems[product_index].quantity);
    } else if (item.action_type == 'remove') {
      product_quantity =
        parseInt(cartItems[product_index].quantity) - parseInt(item.quantity);
      if (product_quantity <= 0)
        return { product_index: product_index, is_delete: true };
    } else {
      product_quantity = parseInt(item.quantity);
    }
  } else {
    product_quantity = parseInt(item.quantity);
  }

  product_plain_price += parseFloat(item.product_price);
  product_single_price += parseFloat(item.product_price);
  product_total_price += parseFloat(item.product_price);
  if (Array.isArray(item.addons) && item.addons.length > 0) {
    item.addons.forEach((addon) => {
      product_single_price += parseFloat(addon.addon_price);
      product_total_price += parseFloat(addon.addon_price);
    });
  } else if (item.addons && Object.keys(item.addons).length > 0) {
    Object.keys(item.addons).forEach((key) => {
      const addon = item.addons[key];
      product_single_price += parseFloat(addon.addon_price);
      product_total_price += parseFloat(addon.addon_price);
    });
  }
  product_total_price = parseFloat(
    product_total_price * parseFloat(product_quantity)
  );
  return {
    product_plain_price,
    product_single_price,
    product_total_price,
    product_index: product_index,
    quantity: product_quantity
  };
};

const formatCartItems = (cart_id, newItem, oldCart) => {
  const item = { ...newItem };
  const productData = getProductData(item, oldCart.cart_items);
  if (productData.is_delete) {
    delete oldCart.cart_items[productData.product_index];
  } else {
    item.cart_id = cart_id;
    item.product_plain_price = productData.product_plain_price;
    item.product_single_price = productData.product_single_price;
    item.product_total_price = productData.product_total_price;
    item.product_index = productData.product_index;
    item.quantity = productData.quantity;
    if (item.action_type) {
      delete item.action_type;
    }
    oldCart.cart_items[productData.product_index] = item;
  }
  const newCart = oldCart;
  const newCartData = calculateCart(oldCart.cart_items);
  newCart.cart_total = newCartData.cart_total;
  newCart.cart_subtotal = newCartData.cart_subtotal;
  newCart.cart_count = newCartData.cart_count;
  newCart.cart_items = newCartData.new_cart_items;

  return newCart;
};

export const formatOrderBody = (cart, statusCode) => {
  const orderBody = {
    order_total: cart.cart_total,
    cart_identifier: cart.cart_id,
    order_type: cart.order_type,
    order_payment_type: 'cash',
    order_schedule: null,
    order_status: 'pending',
    vendor_id: 1,
    customer_id: cart.customer_id || null,
    order_partner: cart.order_partner || null,
    hall_id: cart.hall_id,
    table_id: cart.table_id,
    qr_code: '',
    external_id: null,
    order_id: cart.order_id || null,
    orderId: cart.order_id || null,
    statusCode: statusCode || null
  };
  const products = [];

  Object.keys(cart.cart_items).forEach((productKey) => {
    const currentProduct = cart.cart_items[productKey];
    const newProduct = {
      product_id: currentProduct.product_id,
      product_index: currentProduct.product_index,
      name: currentProduct.product_name,
      quantity: currentProduct.quantity,
      single_price: currentProduct.product_single_price,
      product_single_price: currentProduct.product_single_price,
      product_total_price: currentProduct.product_total_price,
      product_plain_price: currentProduct.product_plain_price,
      total: currentProduct.product_total_price,
      gift: currentProduct.gift,
      discount_type: currentProduct.discount_type,
      discount_amount: currentProduct.discount_amount,
      status: 'pending',
      vendor_id: currentProduct.vendor_id,
      addons: []
    };
    orderBody.vendor_id = currentProduct.vendor_id;
    if (currentProduct.addons && !_.isEmpty(currentProduct.addons)) {
      Object.keys(currentProduct.addons).forEach((addonKey) => {
        const currentAddon = currentProduct.addons[addonKey];
        newProduct.addons.push({
          product_id: currentProduct.product_id,
          addon_id: currentAddon.addon_id,
          name: currentAddon.addon_name,
          quantity: currentProduct.quantity,
          single_price: currentAddon.addon_price,
          total: currentAddon.addon_price
        });
      });
    }
    products.push(newProduct);
  });

  orderBody.carts = products;
  return orderBody;
};

export const cartsSlice = createSlice({
  name: 'carts',
  initialState: {
    carts: {},
    currentCart: '',
    currentCartType: ''
  },
  reducers: {
    createNewCart: (state, action) => {
      const cartExists = validateCartExistence(state, action.payload);
      if (!cartExists) {
        const {
          table_number,
          hall_number,
          order_type,
          table_id,
          hall_id,
          order_partner,
          discount_value,
          discount_type
        } = action.payload;
        const discount_amount = calculateDiscount(
          0,
          discount_type,
          discount_value
        );

        const cart_id = getCartId(table_number, order_type);

        state.currentCart = cart_id;

        state.currentCartType = 'cart';

        state.carts[cart_id] = {
          cart_id: cart_id,
          table_number: order_type == 'dinein' ? table_number : 0,
          hall_number: order_type == 'dinein' ? hall_number : 0,
          table_id: order_type == 'dinein' ? table_id : 0,
          hall_id: order_type == 'dinein' ? hall_id : 0,
          order_type: order_type,
          cart_count: 0,
          cart_total: 0,
          cart_subtotal: 0,
          cart_items: {},
          discount_value: discount_value,
          discount_amount: discount_amount,
          discount_type: discount_type,
          order_partner: order_partner
        };
      }
    },
    createTempCart: (state, action) => {
      const cartExists = validateCartExistence(state, action.payload);
      if (!cartExists) {
        const { order, products } = action.payload;
        console.log('🚀 ~ file: carts.js:252 ~ order:', order);
        const {
          table_number,
          hall_number,
          order_type,
          table_id,
          hall_id,
          discount_amount,
          discount_type,
          order_partner,
          discount_value
        } = order;
        const cart_id = getCartId(table_number, order_type);

        const tempCart = {
          cart_id: cart_id,
          order_id: order.order_id,
          table_number: table_number,
          hall_number: hall_number,
          table_id: table_id,
          hall_id: hall_id,
          order_type: order_type,
          discount_value: discount_value,
          discount_type: discount_type,
          order_partner: order_partner,
          cart_count: 0,
          cart_total: 0,
          cart_subtotal: 0,
          cart_discount: 0,
          cart_discount_type: '',
          cart_items: {},
          is_temp: true
        };
        products.forEach((product) => {
          const { cart_items, cart_count, cart_total, cart_subtotal } =
            formatCartItems(cart_id, product, tempCart);
          tempCart.cart_items = cart_items;
          tempCart.cart_count = cart_count;
          tempCart.cart_total = cart_total;
          const discount_amount = calculateDiscount(
            cart_total,
            discount_type,
            discount_value
          );
          tempCart.discount_amount = discount_amount;
          tempCart.cart_subtotal = cart_subtotal;
        });
        state.carts[cart_id] = tempCart;
        state.currentCart = cart_id;
        state.currentCartType = 'cart';
      }
    },
    setCurrentCart: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.currentCart = action.payload;
      } else {
        state.currentCart = null;
      }
    },
    setCurrentCartType: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.currentCartType = action.payload;
      } else {
        state.currentCartType = '';
      }
    },
    updateCart: (state, action) => {
      const { cart_id } = action.payload;
      const cartExists = validateCartExistence(state, action.payload, cart_id);
      if (cartExists) {
        const {
          table_number,
          order_type,
          cartItem,
          hall_number,
          table_id,
          hall_id,
          order_partner,
          discount_type,
          discount_value
        } = action.payload;
        const { cart_items, cart_count, cart_total, cart_subtotal } =
          formatCartItems(cart_id, cartItem, state.carts[cart_id]);

        const discount_amount = calculateDiscount(
          cart_total,
          discount_type,
          discount_value
        );

        state.carts[cart_id] = {
          cart_id: cart_id,
          table_number: table_number,
          hall_number: hall_number,
          table_id: table_id,
          hall_id: hall_id,
          order_type: order_type,
          cart_count: cart_count,
          cart_total: cart_total,
          cart_subtotal: cart_subtotal,
          cart_items: cart_items,
          is_temp: state.carts[cart_id].is_temp,
          order_id: state.carts[cart_id].order_id || null,
          order_partner: order_partner,
          discount_value: discount_value,
          discount_amount: discount_amount,
          discount_type: discount_type
        };
      }
    },
    deleteCart: (state, action) => {
      const { cart_id } = action.payload;
      if (state.carts[cart_id]) {
        delete state.carts[cart_id];
      }
    },
    discountCart: (state, action) => {
      state.carts[state.currentCart] = action.payload;
      const discount_amount = calculateDiscount(
        action.payload.cart_total,
        action.payload.discount_type,
        action.payload.discount_value
      );
      state.carts[state.currentCart].discount_amount = discount_amount;
    },
    discountProduct: (state, action) => {
      const { product_price, discount_type, discount_value, product_index } =
        action.payload;
      const discount_amount = calculateDiscount(
        product_price,
        discount_type,
        discount_value
      );
      state.carts[state.currentCart].cart_items[product_index].discount_amount =
        discount_amount;
      state.carts[state.currentCart].cart_items[product_index].discount_type =
        discount_type;
    },
    addGiftProduct: (state, action) => {
      const { numberGift, product_index } = action.payload;

      state.carts[state.currentCart].cart_items[product_index].gift =
        numberGift;
    },
    updatePartner: (state, action) => {
      state.carts[state.currentCart].order_partner = action.payload;
    }
  }
});

export const {
  updateCart,
  deleteCart,
  createNewCart,
  setCurrentCart,
  setCurrentCartType,
  createTempCart,
  discountCart,
  discountProduct,
  addGiftProduct,
  updatePartner
} = cartsSlice.actions;
