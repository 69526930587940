import React, { useState } from 'react';
import {
  Button,
  Card,
  Flex,
  List,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Typography
} from 'antd';
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleFilled,
  PrinterFilled,
  PrinterOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useAxiosGet, useAxiosPut } from '../../configs/axios';
import dayjs from 'dayjs'; // Use Day.js for date handling
import styles from './LezzooOrderCard.module.css';
import PreparationModal from '../KitchenView/PreparationModal';
import chef from '../../assets/svg/cheif.svg';
import noteSvg from '../../assets/svg/note-1-svgrepo-com.svg';
import { useTranslation } from 'react-i18next';
import { handlePrintClick } from '../../function/printOnClick';
import useLocalStorage from '../../configs/localStorage';
import {
  KitchenTypesEnum,
  LEZZOOPARTNERID,
  localStorageName,
  OrderStatusesEnum,
  PrintingPrefsEnum
} from '../../configs/constants';
import { useSelector } from 'react-redux';
import {
  kitchenTypeSelector,
  printingPreferencesSelector
} from '../../store/userSlice';
import { ConfirmRefundModal } from '../completedOrders/ConfirmRefundModal';
import { useDispatch } from 'react-redux';
import { decrementLezzooOrdersCount } from '../../store/ordersSlice';

const LezzooOrderCard = ({ order, refresh, cancelationReasons }) => {
  const [isCompleting, setIsCompleting] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [password, setPassword] = useState('');
  const [headerText, setHeaderText] = useState(
    `#${order.external_id || order.order_id}`
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const [auth] = useLocalStorage(localStorageName);

  const dispatch = useDispatch();

  const { request: updateOrder, loading: updateOrderLoading } = useAxiosPut(
    '/orders/changeStatus'
  );

  const { request: completeItemOrder } = useAxiosPut(
    `/kds/updateItemStatus/${order.order_id}`
  );

  // Handle completing an item
  const handleCompleteItem = (order_product_id, status) => {
    // if a status of one product is preparing, all of them are preparing
    // because we take the items as a whole in backend, not singular.
    if (status !== 'prepared' && status === 'pending') {
      message.warning(t('addPreparationTime'));
      return;
    }
    if (status === 'prepared') return;

    completeItemOrder({
      order_product_id
    }).then(() => {
      const allOtherProducts = order.products.filter(
        (product) => product.order_product_id !== order_product_id
      );
      const allItemsCompleted = allOtherProducts?.every((item) => {
        return item.status === OrderStatusesEnum.Prepared;
      });

      if (allItemsCompleted) {
        if (printingPreferences.includes(PrintingPrefsEnum.ORDER_PREPARED)) {
          handlePrintPress();
        }
      }
      message.success(t('itemUpdated'));
      refresh();
    });
  };

  const showPreparingTimeModal = () => {
    if (order.order_status !== 'prepared') setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const calculatePreparationTime = (preparationTime) => {
    const currentTime = dayjs();
    const preparationTimeObj = dayjs(preparationTime);
    const diffInMinutes = preparationTimeObj.diff(currentTime, 'minute');

    return diffInMinutes > 0 ? diffInMinutes : 0;
  };

  const calculateMinutesAgo = (time) => {
    if (!time) return '';

    // Parse the passed time directly as a Date object
    const receivedTime = new Date(time);

    if (isNaN(receivedTime)) return 'Invalid time';

    const currentTime = new Date();
    const diffInMs = currentTime - receivedTime;
    const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
    return diffInMinutes;
  };

  const handlePrintPress = () => {
    const send = {
      order_id: '',
      order_total_with_discount: 0,
      order_payment_type: '',
      order_status: '',
      carts: []
    };
    const productMap = new Map();

    order.products.forEach((product) => {
      const { product_id } = product;

      productMap.set(product_id, {
        product_id: product_id,
        name: product.name,
        quantity: product.quantity,
        product_plain_price: product.product_plain_price,
        product_single_price: product.single_price,
        total: product.product_total,
        status: product.status,
        addons: [],
        price: product.single_price
      });

      if (product.addons && product.addons.length > 0) {
        product.addons.forEach((addon) => {
          const price = addon.price || addon.single_price || 0;
          productMap.get(product_id).addons.push({
            addon_id: addon.addon_id,
            name: addon.name,
            quantity: addon.quantity,
            single_price: price,
            price: price,
            total: price * addon.quantity
          });
        });
      }
    });

    send.carts = Array.from(productMap.values());
    send.order_id = order.external_id;
    send.order_payment_type = order.order_payment_type || '';
    send.order_total_with_discount =
      order.order_total + (order?.discount_amount || 0);
    send.order_paid_online = order?.order_paid_online || 0;
    send.discount_value = order?.discount_value;
    send.discount_type = order?.discount_type;
    send.discount_amount = order?.discount_amount;
    const qr = order?.qr_code;

    const options = {
      data: send,
      vendor: auth?.user,
      subtotal: order?.order_subtotal,
      discount_amount: send?.discount_amount,
      qrCode: qr,
      orderId: send.order_id,
      currentUser: auth?.user?.user_name,
      orderType: order?.order_type,
      orderTypeTitle:
        order?.order_type == 'delivery'
          ? order?.order_partner_name
          : order?.order_type == 'takeaway'
            ? order?.customer_name
            : order?.table_number
    };

    import('../print/kitchen/kitchenTemplate').then(async (module) => {
      const getHtml = module.kitchenTemplate({
        ...options,
        kitchenMode: false,
        data: { carts: send.carts }
      });
      handlePrintClick(getHtml);
    });
  };

  const printingPreferences = useSelector(printingPreferencesSelector);
  const kitchenType = useSelector(kitchenTypeSelector);

  async function handleAcceptOrder() {
    try {
      await updateOrder({
        orderId: order.order_id,
        statusCode: OrderStatusesEnum.InTheKitchen
      });
      dispatch(decrementLezzooOrdersCount());
    } catch (error) {}
  }

  async function handleRejectOrder() {
    updateOrder({
      statusCode: OrderStatusesEnum.Cancelled,
      orderId: order.order_id,
      cancelationReason: cancellationReason,
      password
    })
      .then(() => {
        setPassword('');
        setCancellationReason('');
        setIsCancelModalOpen(false);
        setIsConfirmCancelModalOpen(false);
        if (printingPreferences.includes(PrintingPrefsEnum.ORDER_CANCELLED)) {
          handlePrintPress();
        }
        dispatch(decrementLezzooOrdersCount());
        refresh();
      })
      .catch(() => {
        notification.error({
          message: t('wrong_password'),
          placement: 'topRight',
          duration: 2
        });
      });
  }

  return (
    <>
      <Card
        title={
          <div
            style={{
              display:
                order.order_status === OrderStatusesEnum.Pending
                  ? 'none'
                  : 'block'
            }}
            className={`${styles.headerContainer} ${isCompleting ? styles.completed : ''}`}
            onClick={() => (order.completed ? () => {} : handleCompleteItem())}
          >
            <Flex vertical al>
              <Typography.Text
                style={{
                  color: order.order_type === 'takeaway' ? '#000' : '#fff'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  showPreparingTimeModal();
                }}
              >
                {headerText}
                {!order.order_preparing_est_at &&
                  order.order_status !== 'prepared' && (
                    <ClockCircleOutlined
                      style={{
                        margin: '0 10px',
                        cursor: 'pointer',
                        fontSize: '130%',
                        color: order.order_type === 'takeaway' ? '#000' : '#fff'
                      }}
                    />
                  )}
              </Typography.Text>
              <small
                style={{
                  color: order.order_type === 'takeaway' ? '#000' : '#fff'
                }}
              >
                {order.order_preparing_est_at && (
                  <Row align={'middle'}>
                    <img
                      src={chef}
                      style={{ display: 'inline', marginRight: 4 }}
                      width={17}
                      height={17}
                    />
                    {calculatePreparationTime(order.order_preparing_est_at)}m
                  </Row>
                )}
              </small>
            </Flex>
            {isCompleting && (
              <CheckCircleOutlined
                style={{ color: 'green', marginLeft: '8px' }}
              />
            )}
          </div>
        }
        extra={
          <div
            style={{
              display:
                order.order_status === OrderStatusesEnum.Pending
                  ? 'none'
                  : 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            <span
              style={{
                color: order.order_type === 'takeaway' ? '#000' : '#fff'
              }}
            >
              {calculateMinutesAgo(order.order_kitchen_received_at)}m ago
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: order.order_type === 'takeaway' ? 'black' : 'white'
              }}
            >
              <PrinterOutlined
                onClick={handlePrintPress}
                style={{ fontSize: '1.2rem' }}
              />
            </div>
          </div>
        }
        headStyle={{
          backgroundColor: isCompleting ? 'green' : 'var( --red-color)',
          color: order.order_type !== 'take_away' ? '#fff' : '#000',
          fontWeight: 'bold'
        }}
        hoverable
        actions={[
          order?.products?.length > 5 ? (
            <Typography.Text style={{ color: '#1777ff' }}>
              ↓ {order?.products?.length - 5} more items
            </Typography.Text>
          ) : (
            <Typography.Text style={{ color: 'transparent' }}>
              {order?.products?.length}
            </Typography.Text>
          )
        ]}
      >
        <div
          style={{ minHeight: '250px', maxHeight: '250px', overflowY: 'auto' }}
        >
          <List
            dataSource={order?.products}
            renderItem={(item, index) => (
              <div key={item.id}>
                <List.Item
                  className={`${styles.listItem} ${item.status === 'prepared' ? styles.readyItem : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCompleteItem(item.order_product_id, item.status);
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Row justify={'space-between'}>
                      <Typography.Text strong>
                        {item.quantity}x {item.name}- (
                        {item.product_total?.toLocaleString()} IQD)
                      </Typography.Text>
                      {item.status === 'prepared' && (
                        <CheckCircleOutlined
                          style={{ color: 'green', fontSize: '18px' }}
                        />
                      )}
                    </Row>
                    {item?.addons && item?.addons.length > 0 && (
                      <div className={styles.addonContainer}>
                        <List
                          dataSource={item.addons}
                          renderItem={(addon) => (
                            <Typography.Text
                              className={styles.addonText}
                              style={{ color: '#FF5722' }}
                            >
                              - {addon.name}-(
                              {addon.price?.toLocaleString() + ' IQD'})
                            </Typography.Text>
                          )}
                        />
                      </div>
                    )}

                    {item?.product_instruction &&
                      item?.product_instruction?.length > 2 && (
                        <small
                          style={{
                            color: 'grey',
                            fontWeight: 'bold'
                          }}
                        >
                          <Row align={'middle'}>
                            <img
                              src={noteSvg}
                              style={{
                                display: 'inline',
                                marginRight: 4,
                                fontWeight: 'bold'
                              }}
                              width={17}
                              height={17}
                            />
                            {item.product_instruction}
                          </Row>
                        </small>
                      )}
                  </div>
                </List.Item>
              </div>
            )}
          />

          <PreparationModal
            isModalVisible={isModalVisible}
            refresh={refresh}
            order_id={order.order_id}
            handleModalCancel={handleModalCancel}
          />
          <Modal
            open={isCancelModalOpen}
            onOk={() => {
              setIsConfirmCancelModalOpen(true);
            }}
            confirmLoading={updateOrderLoading}
            onCancel={() => {
              setIsCancelModalOpen(false);
            }}
            closeIcon={
              <CloseCircleFilled
                style={{
                  color: '#7D8FA9',
                  fontSize: 25,
                  float: 'var(--right)'
                }}
              />
            }
          >
            <Radio.Group
              onChange={(item) => {
                setCancellationReason(item.target.value);
              }}
              value={cancellationReason}
            >
              <Space direction="vertical">
                {cancelationReasons && cancelationReasons.length > 0 ? (
                  cancelationReasons.map((item) => {
                    return (
                      <Radio
                        value={item.id}
                        key={item.id}
                        style={{
                          color: 'var(--white-color)'
                        }}
                      >
                        {item.value}
                      </Radio>
                    );
                  })
                ) : (
                  <div />
                )}
              </Space>
            </Radio.Group>
          </Modal>
          {isConfirmCancelModalOpen && (
            <ConfirmRefundModal
              onConfirm={handleRejectOrder}
              setPassword={setPassword}
              loading={updateOrderLoading}
              onClose={() => {
                setIsCancelModalOpen(false);
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '.5rem',
            marginBottom: '-1rem',
            zIndex: 10
          }}
        >
          <Button
            onClick={() => setIsCancelModalOpen(true)}
            style={{
              display:
                order.order_status === OrderStatusesEnum.Pending
                  ? 'block'
                  : 'none',
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            {t('reject')}
          </Button>
          <Button
            onClick={handleAcceptOrder}
            style={{
              display:
                kitchenType === KitchenTypesEnum.SINGLE_KITCHEN
                  ? 'none'
                  : order.order_status === OrderStatusesEnum.Pending
                    ? 'block'
                    : 'none',
              backgroundColor: 'green',
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            {t('accept')}
          </Button>
        </div>
      </Card>
    </>
  );
};

export default LezzooOrderCard;
