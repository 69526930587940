import React, { useState } from 'react';
import DrawerDirection from '../../../utils/DrawerDirection';
import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Image,
  Row,
  Space,
  Spin,
  Tag,
  Input,
  TimePicker,
  message
} from 'antd';
import UserSvg from '../../../components.js/svg/UserSvg';
import LocationSvg from '../../../components.js/svg/LocationSvg';
import CallSvg from '../../../components.js/svg/CallSvg';
import NoteSvg from '../../../components.js/svg/NoteSvg';
import { useAxiosGet, useAxiosPut } from '../../../configs/axios';
import { localStorageName } from '../../../configs/constants';
import useLocaleStorage from '../../../configs/localStorage';
import dayjs from 'dayjs';
import { ClockCircleOutlined } from '@ant-design/icons';

export default function OrderLezzooDrawer({
  open,
  dispatch,
  singleOrderLezzoo,
  getLezzooOrders,
  changeStates
}) {
  const { t } = useTranslation();
  const [auth] = useLocaleStorage(localStorageName);
  const [preparingEst, setPreparingEst] = useState(null);

  const { data: dataSingle } = useAxiosGet(
    `/orders/${singleOrderLezzoo?.order_id}`,
    {
      autoRun: true
    }
  );

  const { request: requestUpdateOrder, loading: loadingChangeStatus } =
    useAxiosPut('/orders/changeStatus');

  const closeDrawer = () => {
    if (dispatch) {
      dispatch({
        type: 'SET_STATE',
        changeStates: {
          showLezzooOrderDrawer: false
        }
      });
    } else {
      changeStates({
        showLezzooOrderDrawer: false,
        singleOrderLezzoo: null
      });
    }
  };

  const acceptOrder = () => {
    if (!preparingEst) {
      message.error('please add estimation time!');
      return;
    }
  
    requestUpdateOrder({
      statusCode: 'preparing',
      orderId: singleOrderLezzoo?.order_id,
      preparingEst
    }).then(() => {
      getLezzooOrders();
      closeDrawer();
    });
  };
  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time)?.format('HH:mm');
    if (formattedTime) {
      setPreparingEst(formattedTime ? formattedTime : null);
    }
  };
  return (
    <DrawerDirection
      closeIcon={null}
      open={open}
      width="50%"
      bodyStyle={{
        backgroundColor: '#F6F6F6'
      }}
      onClose={closeDrawer}
    >
      <section className={style['header-drawer']}>
        <p className="pointer" onClick={closeDrawer}>
          <svg
            width="45"
            height="44"
            viewBox="0 0 45 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22.4349" cy="22.0179" r="21.7557" fill="#1F1D2B" />
            <path
              d="M14.0229 21.9454L30.7023 21.9454M14.0229 21.9454L20.2777 15.8296M14.0229 21.9454L20.2777 28.0611"
              stroke="white"
              strokeWidth="2.22392"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </p>
        <div>
          <p>#{dataSingle?.order?.order_id}</p>
          <button>{t('new_order')}</button>
        </div>
      </section>

      {singleOrderLezzoo?.order_instruction.length ? (
        <section className={style['info-customer']}>
          {singleOrderLezzoo?.order_instruction ? (
            <div className={style['order-instruction']}>
              <p>
                <NoteSvg />
                {t('order_instruction')}
              </p>
              <span>{singleOrderLezzoo?.order_instruction}</span>
            </div>
          ) : null}
        </section>
      ) : null}

      <section className={style['item-orders']}>
        {dataSingle?.products?.map((product) => (
          <Row key={product?.product_id} className={style['single-item']}>
            <Col span={12}>
              <Space>
                <Image src={product.product_image} width={50} height={50} />
                <div className={style['detail-item']}>
                  <h4>{product?.product_name}</h4>
                  <ul>
                    {product?.addons?.map((adn) => (
                      <li key={adn.addon_category_id}>
                        <p>- {adn.addon_category_name}</p>
                        {adn?.addons?.map((ad) => (
                          <span
                            key={ad?.addon_id}
                            className={style['green-color']}
                          >
                            {' '}
                            {ad?.name}
                          </span>
                        ))}
                      </li>
                    ))}
                  </ul>
                </div>
              </Space>
            </Col>
            <Col span={12}>
              <Space
                direction="vertical"
                style={{
                  alignItems: 'flex-end',
                  width: '100%'
                }}
              >
                <p className={style['green-color']}>{product?.quantity}X</p>
                <span>
                  {(product?.price * product?.quantity)?.toLocaleString()}{' '}
                  {auth?.user?.default_currency_code}
                </span>
                {product?.addons?.map((adn) => (
                  <span key={adn.addon_category_id}>
                    {adn?.addons?.map((ad) => (
                      <Row>
                        <span
                          key={ad?.addon_id}
                          className={style['green-color']}
                        >
                          {ad?.single_price?.toLocaleString()}{' '}
                          {auth?.user?.default_currency_code}
                        </span>
                      </Row>
                    ))}
                  </span>
                ))}
              </Space>
            </Col>
            {product?.product_instruction ? (
              <Col span={24}>
                <p className={style['instruction']}>
                  {product.product_instruction}
                </p>
              </Col>
            ) : null}
          </Row>
        ))}

        <TimePicker
          placeholder={t('estimated_time')}
          format="HH:mm"
          onChange={handleTimeChange}
          style={{
            margin: 10,
            width: '50%',
            border: '1px solid #000'
          }}
          suffixIcon={<ClockCircleOutlined style={{ color: '#E4343E' }} />}
        />

        <Button
          style={{
            backgroundColor: 'var(--light-green-color)',
            color: 'var(--main-color)',
            fontSize: 'var(--fs-22)',
            width: '100%',
            height: 48,
            marginTop: 10
          }}
          onClick={acceptOrder}
        >
          {loadingChangeStatus ? <Spin /> : t('accept_order')}
        </Button>
      </section>
    </DrawerDirection>
  );
}
