import React, { Suspense, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate
} from 'react-router-dom';
import {
  Layout,
  Menu,
  theme,
  ConfigProvider,
  Dropdown,
  Button,
  Space
} from 'antd';
import {
  DownOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import routes from './routes';
import Login from './pages/Login';
import './assets/styles/global.scss';
import _ from 'lodash';
import useLocalStorage from './configs/localStorage';
import { languageStorageName, localStorageName } from './configs/constants';
import headphone from '../src/assets/svg/headphone.svg';
import logout from '../src/assets/svg/logout.svg';
import { useAxiosGet } from './configs/axios';
import { useDispatch } from 'react-redux';
import {
  setKitchenType,
  setPrintingPreferences,
  setUserRole,
  setVendorType
} from './store/userSlice';
import { SocketProvider } from './socket/SocketContext';
//
const { Sider, Content } = Layout;

const App = () => {
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const [lang, setLang] = useLocalStorage(languageStorageName, null);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();
  let { pathname } = useLocation();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  pathname = pathname.replace('/', '');

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  if (pathname == 'login') return <Login />;

  const {
    data: dataPermission,
    setData: setDataPermission,
    request: requestDataPermission
  } = useAxiosGet('/users/permissions');

  const { data: userData } = useAxiosGet('/users/me', { autoRun: true });
  const { data: printingPreferences } = useAxiosGet(
    '/vendors/printing-preferences',
    {
      autoRun: true
    }
  );

  useEffect(() => {
    if (userData) {
      dispatch(setKitchenType({ kitchenType: userData?.vendor_kitchen_type }));
      dispatch(
        setUserRole({
          userRoleId: userData?.role_id,
          userRole: userData?.role_name
        })
      );
      dispatch(
        setVendorType({
          vendorType: userData?.vendor_type
        })
      );
    }

    if (printingPreferences) {
      dispatch(
        setPrintingPreferences({ printingPreferences: printingPreferences })
      );
    }
  }, [userData, printingPreferences]);

  function handleChangeLang(lang) {
    i18n.changeLanguage(lang);
    // Set the language to lang
    document.documentElement.lang = lang;
    setLang(lang);
  }

  const onLogout = () => {
    setAuth(null);
    navigate('/login', { replace: true });
    window.location.reload();
  };

  const items = [
    {
      key: '1',
      label: (
        <Button
          style={{ display: 'block', margin: 'auto', width: '100%' }}
          onClick={() => handleChangeLang('en')}
        >
          <span style={{ fontFamily: 'regular-en' }}>English</span>
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%',
            fontFamily: 'regular-ar-kr'
          }}
          onClick={() => handleChangeLang('kr')}
        >
          <span style={{ fontFamily: 'regular-ar-kr' }}>کوردی</span>
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            display: 'block',
            margin: 'auto',
            width: '100%'
          }}
          onClick={() => handleChangeLang('ar')}
        >
          <span style={{ fontFamily: 'regular-ar-kr' }}>عربی</span>
        </Button>
      )
    }
  ];

  useEffect(() => {
    document.documentElement.lang = i18n.language || 'en';

    requestDataPermission().then((res) => {
      // every time update in localStorage too.
      setAuth({
        ...auth,
        permissions: res
      });
      const permissions = res?.map((per) => per.permission_name);
      _.remove(routes, (route) => !_.includes(permissions, route.path));
      const arr = [...routes];
      setDataPermission(arr);
    });
  }, []);

  return (
    <ConfigProvider
      // theme={{
      //   algorithm: defaultAlgorithm,
      //   token: {
      //     colorPrimary: '#2d2d2d',
      //     colorBgBase: '#0e0e0e',
      //     colorText: '#ffffff'
      //   }
      // }}
      theme={{
        token: {
          colorPrimary: 'var(--bg-drawer)'
        }
      }}
      direction={i18n.language == 'en' ? 'ltr' : 'rtl'}
    >
      <SocketProvider>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{
              backgroundColor: 'var(--bg-drawer)',
              width: collapsed ? 200 : 40
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '40px 0',
                borderRadius: 30,
                width: '100%',
                height: 50
              }}
            >
              <span
                onClick={() => {
                  setCollapsed((prev) => !prev);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 40,
                  backgroundColor: 'var(--white-color)',
                  width: 38,
                  height: 38,
                  borderRadius: 10,
                  cursor: 'pointer'
                }}
              >
                {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
              </span>
            </div>
            <div
              className="logo"
              style={{
                display: collapsed ? 'none' : 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 30
              }}
            >
              <p
                style={{
                  color: '#fff',
                  fontSize: '1.3rem',
                  fontWeight: 'bold',
                  fontFamily: 'readex-m'
                }}
              >
                Bridge
                <p
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: 'normal',
                    display: 'inline',
                    marginLeft: 5
                  }}
                >
                  by Lezzoo
                </p>
              </p>
            </div>
            <Menu
              style={{
                marginTop: 25,
                backgroundColor: 'var(--bg-drawer)',
                width: collapsed ? '100%' : 200
                // marginLeft: collapsed ? 10 : 0,
                // marginRight: collapsed ? 10 : 0
              }}
              mode="inline"
            >
              {dataPermission?.map((item, i) => (
                <Menu.Item
                  key={i}
                  title={t(item.label)}
                  className={pathname == item.path ? 'active' : ''}
                >
                  <NavLink to={item.path}>
                    <Space align="center">
                      <span
                        style={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {item.icon}
                      </span>
                      {'  '}
                      <span
                        style={{
                          visibility: collapsed ? 'hidden' : 'visible',
                          color: '#fff'
                        }}
                      >
                        {t(item.label)}
                      </span>
                    </Space>
                  </NavLink>
                </Menu.Item>
              ))}
              <Menu.Item
                title={t('contact_us_for_help')}
                style={{
                  backgroundColor: 'transparent !important'
                }}
              >
                <NavLink
                  to="https://form.asana.com/?k=NEBnM3Zq1gNCZPmF5M8d1A&d=1142527727775231"
                  target="_blank"
                >
                  <Space align="center">
                    <span
                      style={{
                        display: 'block',
                        width: '17px'
                      }}
                    >
                      <img src={headphone} />
                    </span>
                    {'  '}
                    <span
                      style={{
                        visibility: collapsed ? 'hidden' : 'visible',
                        color: '#fff',
                        width: '130px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block'
                      }}
                    >
                      {t('contact_us_for_help')}
                    </span>
                  </Space>
                </NavLink>
                {/* <a
                href="https://form.asana.com/?k=NEBnM3Zq1gNCZPmF5M8d1A&d=1142527727775231"
                target="_blank"
              >
                <Space align="center">
                  <span style={{ color: '#fff' }}>
                    <img src={headphone} />
                  </span>
                  {'  '}
                  <span
                    style={{
                      visibility: 'visible',
                      color: '#fff'
                    }}
                  >
                    {t('contact_us_for_help')}
                  </span>
                </Space>
              </a> */}
              </Menu.Item>
            </Menu>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                transform: 'translateY(50px)',
                flexDirection: 'column',
                paddingBottom: 40
              }}
            >
              <Dropdown
                menu={{ items }}
                placement="bottom"
                arrow
                trigger={['click']}
              >
                <Button
                  style={{
                    textTransform: 'capitalize',
                    minWidth: collapsed ? 50 : '110px',
                    width: collapsed ? 50 : 'calc(100% - 40px)',
                    padding: collapsed ? 0 : '0 20px',
                    backgroundColor: '#DCDCDC'
                  }}
                  icon={<DownOutlined style={{ color: 'black' }} />}
                >
                  {collapsed ? i18n.language : t(i18n.language)}
                </Button>
              </Dropdown>

              {collapsed ? (
                <img
                  style={{
                    marginTop: 50
                  }}
                  onClick={onLogout}
                  src={logout}
                />
              ) : (
                <Button
                  onClick={onLogout}
                  icon={<img src={logout} />}
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 50
                  }}
                >
                  {t('logout')}
                </Button>
              )}
            </div>
          </Sider>
          <Content
            style={{
              minHeight: 280,
              background: 'var(--fifth-color)',
              width: 'calc(80% - 40px)'
            }}
          >
            <Routes>
              {dataPermission?.map((route) => (
                <Route
                  path={route.path}
                  key={route.key}
                  element={
                    <Suspense fallback={<>...</>}>
                      <route.component />
                    </Suspense>
                  }
                />
              ))}
            </Routes>
          </Content>
        </Layout>
      </SocketProvider>
    </ConfigProvider>
  );
};

export default App;
