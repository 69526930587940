import { Fragment, useEffect, useState } from 'react';
import style from './calculator.module.scss';
import BackspaceSvg from '../../../../components.js/svg/BackspaceSvg';
export default function Calculator({ lastPriceUpdate = () => {} }) {
  const [activeButton, setActiveButton] = useState(null);
  const [price, setPrice] = useState(0);

  const handleButtonClick = (number, type) => {
    if (type === 'add') {
      setPrice((prev) => {
        const newPrice = prev * 10 + number;
        lastPriceUpdate(newPrice);
        return newPrice;
      });
    } else if (type === 'back') {
      setPrice((prev) => {
        const newPrice = Math.floor(prev / 10);
        lastPriceUpdate(newPrice);
        return newPrice;
      });
    }

    setActiveButton(number);
    setTimeout(() => {
      setActiveButton(null);
    }, 200);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!isNaN(event.key) && event.key !== ' ') {
        handleButtonClick(parseInt(event.key), 'add');
      } else if (event.key === 'Backspace') {
        handleButtonClick(10, 'back');
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return (
    <Fragment>
      <div className={style['total']}>
        <p>{price?.toLocaleString()}</p>
        <span>IQD</span>
      </div>
      <div className={style['calculator']} tabIndex={0}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0].map((i) => {
          return (
            <button
              key={i}
              className={`${i == 10 ? style['backspace'] : ''} ${
                activeButton == i ? style['active'] : ''
              }`}
              onClick={() => handleButtonClick(i, i == 10 ? 'back' : 'add')}
              tabIndex={0}
            >
              {i == 10 ? <BackspaceSvg /> : i}
            </button>
          );
        })}
      </div>
    </Fragment>
  );
}
