import { createSlice } from '@reduxjs/toolkit';
import { KitchenTypesEnum } from '../configs/constants';

const initialState = {
  kitchenType: KitchenTypesEnum.SINGLE_KITCHEN,
  printingPreferences: [],
  userRoleId: -1,
  userRole: '',
  vendorType: ''
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setKitchenType: (state, action) => {
      state.kitchenType = action.payload.kitchenType;
    },
    setPrintingPreferences: (state, action) => {
      state.printingPreferences = action.payload.printingPreferences;
    },
    setUserRole: (state, action) => {
      state.userRoleId = action.payload.userRoleId;
      state.userRole = action.payload.userRole;
    },
    setVendorType: (state, action) => {
      state.vendorType = action.payload.vendorType;
    }
  }
});

export const kitchenTypeSelector = (state) => state.users.kitchenType;
export const printingPreferencesSelector = (state) =>
  state.users.printingPreferences;

export const userRoleSelector = (state) => state.users.userRole;
export const vendorTypeSelector = (state) => state.users.vendorType;

export const {
  setKitchenType,
  setPrintingPreferences,
  setUserRole,
  setVendorType
} = usersSlice.actions;
export default usersSlice.reducer;
